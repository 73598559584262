import { GetAllItems } from "./GetAllItems";

export const SelectItems = (sceneData, selectItems, setSelected) => {
  let selected = [];
  const allItems = GetAllItems(sceneData);
  allItems.forEach((item) => {
    selectItems.forEach((selectItem) => {
      if (item.uniqueId == selectItem.uniqueId) {
        selected.push(item);
      }
    });
  });
  setTimeout(() => {
    setSelected(selected);
  }, 100);
};
