import axios from "axios";
import { toast } from "react-toastify";
import "./SetAddressModal.scss";
import { API_URL } from "../../../env";
import { useEffect } from "react";

export const SetAddressModal = (props) => {
  const { selected, setShowAddressModal, defaultValue, shopId } = props;

  const sendUpdateToAPI = (items) => {
    const body = {
      shopId: shopId,
      items: items,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(API_URL + "/VirtualStore/postElements", JSON.stringify(body), {
        headers,
      })
      .then((response) => {
        if (response.data.status.success) {
          const items = response.data.items;
          items.forEach((item, key) => {
            if (selected[key].uniqueId === item.uniqueId) {
              selected[key].children[0].text = item.address;
              selected[key].address = item.address;
              selected[key].userData.address = item.address;
              selected[key].updateMatrix();
            }
          });
          setShowAddressModal(false);
          toast("Poprawnie zmieniono adres", { type: "success" });
        } else {
          toast(response.data.status.message, { type: "error" });
        }
      });
  };

  const saveValue = () => {
    const input = document.querySelector("#input-value");
    const value = input.value;
    if (selected.length === 1) {
      let items = [
        {
          uniqueId: selected[0].uuid,
          address: value,
          shapeId: selected[0].shapeId,
          objectName: selected[0].shape,
          color: selected[0].color,
          x: Math.round(Number(selected[0].position.x * 100)),
          y: Math.round(Number(selected[0].position.z * 100)),
          w: selected[0].geometry.parameters.width * 100,
          h: selected[0].geometry.parameters.height * 100,
          rX: selected[0].rotation.x,
          rY: selected[0].rotation.y,
          rZ: selected[0].rotation.z,
        },
      ];
      sendUpdateToAPI(items);
    }
  };

  useEffect(() => {
    const input = document.querySelector("#input-value");
    input.focus();
    input.select();
  }, []);

  return (
    <div className="set-address-modal-container">
      <label>Wpisz nowy adres</label>
      <input
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            saveValue();
          } else if (e.keyCode === 27) {
            setShowAddressModal(false);
          }
        }}
        type="text"
        id="input-value"
        defaultValue={defaultValue}
      />
    </div>
  );
};
