export const Aligment = (arrayElements, direction) => {
  // LEFT
  if (direction === 'left') {
    let minX;
    arrayElements.forEach((item)=>{
      if(!minX) {minX = item.position.x} else {
        if(item.position.x < minX) {
          minX = item.position.x
        }
      }      
    })
    arrayElements.forEach((item)=>{
      item.position.x = minX
    })
  }

  // RIGHT
  if (direction === 'right') {
    let minX;
    arrayElements.forEach((item)=>{
      if(!minX) {minX = item.position.x} else {
        if(item.position.x > minX) {
          minX = item.position.x
        }
      }      
    })
    arrayElements.forEach((item)=>{
      item.position.x = minX
    })
  }

  // TOP
  if (direction === 'top') {
    let minZ;
    arrayElements.forEach((item)=>{
      if(!minZ) {minZ = item.position.z} else {
        if(item.position.z < minZ) {
          minZ = item.position.z
        }
      }      
    })
    arrayElements.forEach((item)=>{
      item.position.z = minZ
    })
  }

  // BOTTOM
  if (direction === 'bottom') {
    let minZ;
    arrayElements.forEach((item)=>{
      if(!minZ) {minZ = item.position.z} else {
        if(item.position.z > minZ) {
          minZ = item.position.z
        }
      }      
    })
    arrayElements.forEach((item)=>{
      item.position.z = minZ
    })
  }

  // Edges
  if (direction === 'edges') {
    if (arrayElements.length === 2) {
      arrayElements = _.sortBy(arrayElements, (item)=>item.position.x);
      
      const distance = Number((arrayElements[1].position.x - arrayElements[0].position.x).toFixed(2));
      const sumWidth = Number((arrayElements[1].geometry.parameters.width)/2 + (arrayElements[0].geometry.parameters.width)/2);

      const translatewidth = distance-sumWidth;

      arrayElements[1].translateX(-translatewidth)
    }
  }
}