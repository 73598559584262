import React from "react";
import "./RightToolbar.scss";
import { BiMove, BiUndo, BiSave, BiSpreadsheet } from "react-icons/bi";
import { TbRotate, TbRotate2 } from "react-icons/tb";
import { MdAddCircleOutline, MdZoomOutMap, MdHelp } from "react-icons/md";
import { BiSelection } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { SaveConfigToAPI } from "../models/SaveConfigToAPI";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import { API_URL } from "../../env";
import { toast } from "react-toastify";
import { SaveMapToAPI } from "../models/SaveMapToAPI";

export const RightToolbar = (props) => {
  const {
    showGrid,
    setShowGrid,
    setTransformControlsMode,
    transformControlsMode,
    sceneData,
    activeItem,
    rotate15degMode,
    setRotate15degMode,
    setAddItemModalOpen,
    setBackgroundImage,
    setScaleBackground,
    scaleBackground,
    setMapDataLoaded,
    setMapLoading,
    setHelpModalActive,
    setScaleModal,
  } = props;

  let { shopId } = useParams();

  const addItemMenuHandler = () => {
    setAddItemModalOpen(true);
  };

  const getMapData = () => {
    const body = {
      mapAlso: true,
      shopId: shopId,
    };
    let headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(API_URL + "/VirtualStore/map", JSON.stringify(body), { headers })
      .then((response) => {
        if (response.data.status.success) {
          setBackgroundImage(response.data.image);
          setScaleBackground(response.data.scale);
        } else {
          toast(response.data.status.message, { type: "error" });
        }
      });
  };

  return (
    <div className="top-menu-container">
      <div
        className={`item  ${showGrid ? "active" : "disabled"}`}
        onClick={() => setShowGrid(!showGrid)}
        id="grid-icon"
      >
        <BiSelection />
      </div>
      <div
        className={`item disabled`}
        onClick={() => getMapData()}
        id="map-icon"
      >
        <BiSpreadsheet />
      </div>
      <div
        className={`item separator ${
          transformControlsMode === "scale" ? "active" : "disabled"
        }`}
        onClick={() => {
          setTransformControlsMode("scale");
          setScaleModal(true);
        }}
        id="scale-map-icon"
      >
        <MdZoomOutMap />
      </div>
      <div
        className={`item ${
          transformControlsMode === "translate" ? "active" : "disabled"
        }`}
        onClick={() => setTransformControlsMode("translate")}
        id="move-icon"
      >
        <BiMove />
      </div>
      <div
        className={`item ${
          transformControlsMode === "rotate" ? "active" : "disabled"
        }`}
        onClick={() => setTransformControlsMode("rotate")}
        id="rotate-icon"
      >
        <TbRotate />
      </div>
      <div
        className={`item separator ${rotate15degMode ? "active" : "disabled"}`}
        onClick={() => setRotate15degMode(!rotate15degMode)}
        id="rotate-step-icon"
      >
        <TbRotate2 />
      </div>
      <div
        className={`item ${activeItem ? "active" : "disabled"} separator`}
        onClick={() => addItemMenuHandler()}
        id="add-icon"
      >
        <MdAddCircleOutline />
      </div>
      <div
        className={`item ${props.itemEdited ? "active" : "disabled"}`}
        id="undo-icon"
      >
        <BiUndo />
      </div>
      <div
        className={`item ${props.itemEdited ? "active" : "disabled"}`}
        onClick={() => {
          SaveConfigToAPI({
            shopId: shopId,
            sceneData: sceneData,
            setItemEdited: props.setItemEdited,
          });
          SaveMapToAPI(sceneData.children, shopId, scaleBackground, {
            setMapDataLoaded: setMapDataLoaded,
            setMapLoading: setMapLoading,
          });
        }}
        id="save-icon"
      >
        <BiSave />
      </div>
      <div
        className={`item ${activeItem ? "active" : "disabled"} separator`}
        onMouseEnter={() => {
          setHelpModalActive(true);
        }}
        onMouseLeave={() => {
          setHelpModalActive(false);
        }}
        id="add-icon"
      >
        <MdHelp />
      </div>
      <ReactTooltip
        anchorId="grid-icon"
        place="left"
        html="<b>Siatka</b><i> <br> Włącz/Wyłącz siatkę (1m x 1m).</i>"
      />
      <ReactTooltip
        anchorId="move-icon"
        place="left"
        html="<b>Przenieś element</b><i> <br> Jeśli włączony, możesz przenosić wybrane elementy.<br>
          Użyj <strong>← ↑ → ↓</strong> aby przenieść element o 1cm <br>
          Użyj <strong>← ↑ → ↓ + CTRL</strong> aby przenieść element o 10cm <br>
          Użyj <strong>← ↑ → ↓ + SHIFT</strong> aby przenieść element o 100cm</i>"
      />
      <ReactTooltip
        anchorId="rotate-icon"
        place="left"
        html="<b>Obróć element</b><i> <br> Jeśli włączony, możesz obracać wybrany element.<br>
          Jeżeli aktywowany tryb obrotu skokowego, obrót<br>
          następuje co 15° (ikona poniżej).</i>"
      />
      <ReactTooltip
        anchorId="rotate-step-icon"
        place="left"
        html="<b>Tryb obrotu skokowego</b><i> <br> Jeśli włączony, obrót następuje co 15°.<br> Jeżeli wyłączony obrót następuje co 1°.</i>"
      />
      <ReactTooltip
        anchorId="scale-icon"
        place="left"
        html="<b>Skalowanie</b><i> <br> Jeśli włączony, możesz skalować wybrany element.</i>"
      />
      <ReactTooltip
        anchorId="add-icon"
        place="left"
        html="<b>Dodaj Element</b><i> <br> Wybierz, jeżeli chcesz dodać<br>nowy element do planu.</i>"
      />
      <ReactTooltip
        anchorId="save-icon"
        place="left"
        html="<b>Zapisz</b><i> <br> Wybierz, jeżeli chcesz zapisać zmiany.<br>
          Możesz użyć także skrótu <strong>CTRL + S</strong> aby zapisać.</i>"
      />
      <ReactTooltip
        anchorId="scale-map-icon"
        place="left"
        html="<b>Skalowanie mapy</b><i> <br> Po wgraniu mapy, włącz tryb skalowania i dopasuj element do mapy.<br>
         Zapisz zmiany, aby skala mapy została zapisana..</i>"
      />
      <ReactTooltip
        anchorId="map-icon"
        place="left"
        html="<b>Włącz plan sklepu</b><i> <br> Wybierz, jeżeli chcesz wyświetlić plan sklepu.<br>
          Mapa wyświetli się w tle.</i>"
      />
      <ReactTooltip
        anchorId="undo-icon"
        place="left"
        html="<b>Cofnij zmianę</b><i> <br> Cofnij ostatnio wprowadzoną zmianę.<br>
        Możesz użyć także skrótu <strong>CTRL + Z</strong> aby cofnąć zmianę.</i>"
      />
    </div>
  );
};
