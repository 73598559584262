import "./HelpModal.scss";

export const HelpModal = () => {
  return (
    <div className="help-modal">
      <div>
        <h3>Skróty klawiaturowe:</h3>
      </div>
      <hr />
      <div className="help-modal-content">
        <div style={{ paddingRight: "50px" }}>
          <table>
            <tr>
              <td>
                <strong>← → ↓ ↑</strong> :
              </td>
              <td>Przesuwanie elementów o 1cm</td>
            </tr>

            <tr>
              <td>
                <strong>CTRL</strong> + <strong>← → ↓ ↑</strong> :
              </td>
              <td>Przesuwanie elementów o 10cm</td>
            </tr>

            <tr>
              <td>
                <strong>SHIFT</strong> + <strong>← → ↓ ↑</strong> :
              </td>
              <td>Przesuwanie elementów o 100cm</td>
            </tr>
            <tr>
              <td>
                <strong>DELETE</strong>:
              </td>
              <td>Usuwanie zaznaczonych elementów</td>
            </tr>
          </table>
        </div>
        <div>
          <table>
            <tr>
              <td>
                <strong>CTRL</strong> + <strong>S</strong>:
              </td>
              <td>Zapisywanie wszystkich zmian</td>
            </tr>
            <tr>
              <td>
                <strong>CTRL</strong> + <strong>C</strong>:
              </td>
              <td>Kopiowanie zaznaczonych elementów</td>
            </tr>
            <tr>
              <td>
                <strong>CTRL</strong> + <strong>V</strong>:
              </td>
              <td>Wklejanie zaznaczonych elementów</td>
            </tr>
            <tr>
              <td>
                <strong>CTRL</strong> + <strong>Z</strong>:
              </td>
              <td>Cofanie ostatnio wprowadzonej zmiany</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};
