import React from "react";
import "./ChooseStepComponent.scss";

export const ChooseStepComponent = (props) => {
  const { step, setStep, setContextMenuAction, setContextMenuActionValue } =
    props;

  const copyHandler = (step) => {
    setStep(step);
    setContextMenuAction(setContextMenuActionValue);
  };

  return (
    <div>
      <button
        className={`${step === -2 ? "enable-style" : "button-style"}`}
        onClick={() => copyHandler(-2)}
      >
        -2
      </button>
      <button
        className={`${step === -1 ? "enable-style" : "button-style"}`}
        onClick={() => copyHandler(-1)}
      >
        -1
      </button>
      <button
        className={`${step === 1 ? "enable-style" : "button-style"}`}
        onClick={() => copyHandler(1)}
      >
        +1
      </button>

      <button
        className={`${step === 2 ? "enable-style" : "button-style"}`}
        onClick={() => copyHandler(2)}
      >
        +2
      </button>
    </div>
  );
};
