import axios from "axios";
import { API_URL } from "../../env";
import { toast } from "react-toastify";

export function SaveConfigToAPI(props) {
  const { shopId, sceneData } = props;

  let body = {
    shopId: shopId,
  };
  let items = [];
  const scene = sceneData;
  scene.children.forEach((child) => {
    if (child.type === "Group" && child.children.length > 0) {
      child.children.forEach((item) => {
        if (!isNaN(item.uuid)) {
          let rotation = (item.rotation._z * 180) / Math.PI;
          rotation =
            rotation < 0 ? Math.round(rotation * -1) : Math.round(rotation);
          items.push({
            uniqueId: item.uuid,
            shapeId: item.userData.shapeId,
            objectName: item.shape,
            address: item.address,
            department: item.userData.department,
            color: item.userData.color,
            x: Math.round(Number(item.position.x * 100)),
            y: Math.round(Number(item.position.z * 100)),
            w: item.geometry.parameters.width * 100,
            h: item.geometry.parameters.height * 100,
            rX: item.rotation._x,
            rY: item.rotation._y,
            rZ: item.rotation._z,
            rotation: rotation,
          });
        }
      });
    }
  });
  const headers = {
    "Content-Type": "application/json",
  };
  body.items = items;
  axios
    .post(API_URL + "/VirtualStore/postElements", JSON.stringify(body), {
      headers,
    })
    .then((response) => {
      if (response.data.status.success) {
        toast("Zapisano pomyślnie", { type: "success" });
      } else {
        toast(response.data.status.message, { type: "error" });
      }
    });
}
