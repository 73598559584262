import axios from "axios";
import { useState } from "react";
import { MdOutlineExpandMore, MdOutlineExpandLess } from "react-icons/md";
import { API_URL } from "../../env";
import { useParams } from "react-router-dom";
import { AddItemToCanvas } from "../models/AddItemToCanvas";

export const RightAdvancedMenuSection = (props) => {
  const {
    attributes,
    activeItem,
    setContextMenuAction,
    setObjectOnCanvas,
    allObjects,
  } = props;
  const [open, setOpen] = useState(true);

  const { shopId } = useParams();

  const deleteSelected = () => {
    setContextMenuAction("deleteItem");
  };
  const addNewItemToMap = (items) => {
    items.forEach((item) => {
      AddItemToCanvas({
        setObjectOnCanvas,
        key: item.uniqueId,
        rX: item.rX,
        rY: item.rY,
        rZ: item.rZ,
        uniqueId: item.uniqueId,
        shapeId: item.shapeId,
        address: item.address,
        department: item.department,
        shape: item.objectName,
        color: item.color,
        position: [item.x / 100, item.z / 100 + item.h / 100 / 2, item.y / 100],
        shelfs: item.shelfs,
        allObjects: allObjects,
        rotation: item.rotation,
        x1: item.x1_frame,
        x2: item.x2_frame,
        y1: item.y1_frame,
        y2: item.y2_frame,
        attributes: item.attributes,
      });
    });
  };

  const sendNewValues = (e, key, activeItemItem) => {
    const mesh = {
      uniqueId: activeItemItem.uniqueId,
      attributes: activeItemItem.userData.attributes,
    };

    const bodyToSend = {
      shopId: shopId,
      items: [mesh],
    };

    mesh.attributes[key].value = e.target.value;
    activeItemItem.attributes = mesh.attributes;
    console.log(bodyToSend);

    axios
      .post(API_URL + "/VirtualStore/postAttributes", bodyToSend)

      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          deleteSelected();
          setTimeout(() => {
            if (res.data.items.length > 0) {
              addNewItemToMap(res.data.items);
            }
          }, 1000);
        }
        activeItemItem.matrixWorldNeedsUpdate = true;
      });
  };
  return (
    attributes &&
    attributes.length > 0 && (
      <>
        {attributes.map((item, key) => {
          return (
            <div key={key}>
              <h5>
                <div
                  className={`item-header ${open ? "item-header-active" : ""}`}
                  onClick={() => setOpen(!open)}
                >
                  {item.label}
                  {open ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
                </div>
              </h5>
              <hr />
              {open ? (
                <>
                  {/* TEXT */}
                  {item.type === "Text" ? (
                    <>
                      <div className="item-content">
                        <input
                          type="text"
                          defaultValue={item.value}
                          onSubmit={(e) => sendNewValues(e, key, activeItem)}
                        />
                      </div>
                      <hr />
                    </>
                  ) : (
                    ""
                  )}
                  {/* LIST */}
                  {item.type === "List" ? (
                    <>
                      <div className="item-content">
                        <select
                          onChange={(e) => sendNewValues(e, key, activeItem)}
                        >
                          {item.values.map((value, key) => {
                            return <option key={key}>{value}</option>;
                          })}
                        </select>
                      </div>
                      <hr />
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </>
    )
  );
};
