import { useEffect, useState } from "react";
import "./RightAdvancedMenu.scss";
import { RxDot, RxDotFilled } from "react-icons/rx";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { RightAdvancedMenuSection } from "./RightAdvancedMenuSection";

export const RightAdvancedMenu = (props) => {
  const {
    advancedMenuActive,
    selected,
    setContextMenuAction,
    setObjectOnCanvas,
    allObjects,
  } = props;
  const [activeItem, setActiveItem] = useState(0);

  useEffect(() => {
    setActiveItem(0);
  }, [advancedMenuActive]);

  return (
    <div
      className={`right-advanced-menu ${
        advancedMenuActive ? "active-right-advanced-menu" : ""
      }`}
    >
      <div className="item-container">
        {selected.map((item, key) => {
          return (
            <div
              key={key}
              className="menu-item"
              style={{
                marginLeft: -1 * activeItem * 25 + "vw",
                left: key * 25 + "vw",
              }}
            >
              <h5 style={{ textAlign: "center" }}>
                Item #{item.uuid} - {item.address}
              </h5>
              <hr />
              <RightAdvancedMenuSection
                key={key}
                attributes={item.userData.attributes}
                activeItem={item}
                setContextMenuAction={setContextMenuAction}
                setObjectOnCanvas={setObjectOnCanvas}
                allObjects={allObjects}
              />
            </div>
          );
        })}
      </div>
      <div className="pagination">
        {selected.map((item, key) => {
          return (
            <div
              key={key}
              id={`item-${key}`}
              data-tooltip-content="test"
              data-tooltip-id="showTooltip"
              className={`page-item`}
              onClick={() => {
                setActiveItem(key);
              }}
            >
              {key === activeItem ? (
                <RxDotFilled size={25} />
              ) : (
                <RxDot size={25} />
              )}
              <ReactTooltip
                anchorId={`item-${key}`}
                place="top"
                html={`<b>${item.address}</b>`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
