import React from 'react';
import './FilterModal.scss';
import {AiOutlineClose} from 'react-icons/ai';
import { Calendar } from 'react-calendar';
import { useState } from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

export const FilterModal = (props) => {
  const { filterModal } = props
  const closeHandler = () => {
    props.setFilterModal(null);
  }

  const CalendarTemplate = (item, key) => {
    return (
      <div key={key} className='filter' style={{margin: '0px 10px'}}>
        <div style={{textAlign:'center', fontWeight: 'bold', margin: '15px 0px'}}>{item.name}</div>
        {/* Mobile Calendar */}
        <div className='mobileCalendar'>
          <input type="date" />
        </div>
        
        {/* Desktop Calendar */}
        <div className='desktopCalendar'>
          <Calendar minDate={new Date(item.dateTimeMin)} maxDate={new Date(item.dateTimeMax)} defaultActiveStartDate={new Date(item.dateTimeCurrent)} />
        </div>
      </div>
    )
  }

  const TrackbarTemplate = (item, key) => {
    const [trackbarValue, setTrackbarValue] = useState(0);

    return (
      <div key={key} className='filter' style={{width: '100%'}}>
        <div style={{textAlign:'center', fontWeight: 'bold', margin: '15px 0px'}}>{item.name}</div>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h6 style={{marginRight: '10px'}}>{item.valueHeader}</h6>
          {trackbarValue}
          <RangeSlider
            className="single-thumb"
            defaultValue={[0, 50]}
            thumbsDisabled={[true, false]}
            rangeSlideDisabled={true}
            onInput={(event)=>setTrackbarValue(event[1])}
          />
        </div>
      </div>
    )
  }

  const ComboTemplate = (item, key) => {
    return (
      <div style={{textAlign:'center', fontWeight: 'bold', margin: '15px 0px'}}>{item.name}</div>
    )
  }

  return (
    <div className="filter-modal">
      <div className="close-button" onClick={()=>closeHandler()}>
        <AiOutlineClose />
      </div>
      <div style={{display: 'flex', flexDirection:'column', width: '100%'}}>
        {filterModal.map((item, key)=>{
          if (item.type === 'calendar') {
            return CalendarTemplate(item, key);
          } else if (item.type === 'trackbar') {
            return TrackbarTemplate(item, key);
          } else if (item.type === 'combo') {
            return ComboTemplate(item, key)
          }
          return 0;
        })}
      </div>
      <button>Zapisz</button>
    </div>
  )
}