import React, { useMemo } from "react";
import * as THREE from "three";
import { Line, Text } from "@react-three/drei";
import { useRef } from "react";

export const Object = (props) => {
  const { allObjects } = props;
  const objectRef = useRef();
  let fontSize;

  // let zoom = state.camera.zoom;

  if (props.address && props.address.length === 8) {
    fontSize = 0.23;
  } else if (props.address && props.address.length === 9) {
    fontSize = 0.22;
  } else if (props.address && props.address.length === 11) {
    fontSize = 0.18;
  } else {
    fontSize = 0.12;
  }
  let sideBorder = props.attributes ? props.attributes[0].value : "Brak";
  return (
    <>
      <mesh
        {...props}
        uuid={props.uniqueId}
        userData={{
          objectId: props.uniqueId,
          shapeId: props.shapeId,
          address: props.address,
          department: props.department,
          color: props.color,
          attributes: props.attributes,
        }}
        rotation={[props.rX, props.rY, props.rZ]}
        ref={objectRef}
        needsUpdate={true}
      >
        <primitive object={allObjects[props.shape]} attach={"geometry"} />
        <meshPhongMaterial color={"#" + props.color} />
        {
          <Text
            textAlign="center"
            anchorX="center"
            anchorY="middle"
            position={[
              0,
              allObjects[props.shape].parameters.height / 2 + 0.01,
              0,
            ]}
            maxWidth={allObjects[props.shape].parameters.width - 0.1}
            rotation={[-Math.PI / 2, 0, 0]}
            fontSize={fontSize}
            color="black"
          >
            {props.address}
          </Text>
        }
        {
          <Line
            color="black"
            lineWidth={1}
            opacity={0.5}
            points={[
              [
                -allObjects[props.shape].parameters.width / 2 + 0.01,
                -allObjects[props.shape].parameters.height / 2,
                allObjects[props.shape].parameters.depth / 2 + 0.0005,
              ],
              [
                allObjects[props.shape].parameters.width / 2 - 0.01,
                -allObjects[props.shape].parameters.height / 2,
                allObjects[props.shape].parameters.depth / 2 + 0.0005,
              ],
            ]}
          />
        }
        {
          <Line
            color="black"
            lineWidth={sideBorder === "Lewa" ? 5 : 1}
            opacity={sideBorder === "Lewa" ? 1 : 0.5}
            points={[
              [
                -allObjects[props.shape].parameters.width / 2,
                -allObjects[props.shape].parameters.height / 2 + 0.01,
                allObjects[props.shape].parameters.depth / 2 + 0.005,
              ],
              [
                -allObjects[props.shape].parameters.width / 2,
                allObjects[props.shape].parameters.height / 2 - 0.01,
                allObjects[props.shape].parameters.depth / 2 + 0.0005,
              ],
            ]}
          />
        }
        {
          <Line
            color="black"
            lineWidth={sideBorder === "Prawa" ? 5 : 1}
            opacity={sideBorder === "Prawa" ? 1 : 0.5}
            points={[
              [
                allObjects[props.shape].parameters.width / 2,
                -allObjects[props.shape].parameters.height / 2 + 0.01,
                allObjects[props.shape].parameters.depth / 2 + 0.0005,
              ],
              [
                allObjects[props.shape].parameters.width / 2,
                allObjects[props.shape].parameters.height / 2 - 0.01,
                allObjects[props.shape].parameters.depth / 2 + 0.0005,
              ],
            ]}
          />
        }
        {/* {shelfList} */}
      </mesh>
    </>
  );
};
