import React from "react";
import "./CenterMenuComponent.scss";
import { AiFillCloseCircle } from "react-icons/ai";
import axios from "axios";
import { API_URL } from "../../env";
import { toast } from "react-toastify";

export const CenterMenuComponent = (props) => {
  const { backgroundImage, setBackgroundImage, shopId, setMapLoading } = props;

  function fileHandler(file) {
    let data = new FormData();
    data.append("myImg", file);

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    setMapLoading(true);
    axios
      .post(API_URL + "/VirtualStore/pdfToImage", data, {
        responseType: "blob",
        headers,
      })
      .then((response) => {
        const imgUrl = URL.createObjectURL(response.data);
        setBackgroundImage(imgUrl);
        setTimeout(() => {
          setMapLoading(false);
        }, 500);
      });
  }

  function eraseMap() {
    const body = {
      shopId: shopId,
      image: null,
      scale: 1,
    };
    axios
      .post(API_URL + "/VirtualStore/postBackground", body)
      .then((response) => {
        if (response.data.status.success) {
          setBackgroundImage(null);
        } else {
          toast(response.data.status.message, { type: "error" });
        }
      });
  }

  return (
    <>
      <h4>Własne zdjęcie tła:</h4>
      {backgroundImage && (
        <div className="image-container">
          <div className="close-button" onClick={() => eraseMap()}>
            <AiFillCloseCircle />
          </div>
          <img src={backgroundImage} alt="Podglad Mapy" />
        </div>
      )}
      {!backgroundImage && (
        <>
          <form encType="multipart/form-data" action="">
            <input
              type="file"
              name="myImage"
              onChange={(event) => {
                fileHandler(event.target.files[0]);
              }}
            />
          </form>
        </>
      )}
    </>
  );
};
