import React from "react";
import "./BottomSummaryBar.scss";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../../env";
import { Oval } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { GetAllItems } from "../models/GetAllItems";

export const BottomSummaryBar = (props) => {
  let { shopId } = useParams();

  const { activeItem, setSelected, selected, sceneData } = props;
  const [colorpickerActive, setColorpickerActive] = useState(false);
  const [comboboxOpened, setComboboxOpened] = useState(false);
  const [arrayOfElements, setArrayOfElements] = useState([]);
  const [copyArrayOfElements, setCopyArrayOfElements] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  let AllItems = GetAllItems(sceneData);

  let minXMesh = _.minBy(AllItems, (item) => item.position.x);
  let minYMesh = _.minBy(AllItems, (item) => item.position.z);
  let minXGlobal = minXMesh.position.x * 100;
  let minYGlobal = minYMesh.position.z * 100;

  let minX;
  let minY;
  let maxX;
  let maxY;

  if (selected.length >= 2) {
    selected.forEach((element) => {
      // MIN X
      if (minX) {
        if (minX > element.position.x) {
          minX = element.position.x;
        }
      } else {
        minX = element.position.x;
      }

      // MAX X
      if (maxX) {
        if (maxX < element.position.x) {
          maxX = element.position.x;
        }
      } else {
        maxX = element.position.x;
      }

      // MIN Y
      if (minY) {
        if (minY > element.position.z) {
          minY = element.position.z;
        }
      } else {
        minY = element.position.z;
      }

      // MAX Y
      if (maxY) {
        if (maxY < element.position.z) {
          maxY = element.position.z;
        }
      } else {
        maxY = element.position.z;
      }
    });

    minX = (minXGlobal / 100) * -1 + minX;
    maxX = (minXGlobal / 100) * -1 + maxX;
    minY = (minYGlobal / 100) * -1 + minY;
    maxY = (minYGlobal / 100) * -1 + maxY;
  }

  const colorPickerHandle = () => {
    setColorpickerActive(!colorpickerActive);
  };

  const showAddressesHandler = (event) => {
    setComboboxOpened(true);

    const body = {
      shopId: shopId,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        API_URL + "/VirtualStore/getNonUsedElements",
        JSON.stringify(body),
        { headers }
      )
      .then((response) => {
        setDataLoaded(true);
        setArrayOfElements(response.data.elementRows);
        setCopyArrayOfElements(response.data.elementRows);
        const container = document.querySelector(".combo-box-container");
        container.style.left = event.pageX;
      });
  };

  const chooseAddressHandler = (address) => {
    const body = {
      shopId: shopId,
      items: [
        {
          uniqueId: activeItem.uuid,
          address: address,
          shapeId: activeItem.shapeId,
          objectName: activeItem.shape,
          color: activeItem.color,
          x: Math.round(Number(activeItem.position.x * 100)),
          y: Math.round(Number(activeItem.position.z * 100)),
          w: activeItem.geometry.parameters.width * 100,
          h: activeItem.geometry.parameters.height * 100,
          rX: activeItem.rotation.x,
          rY: activeItem.rotation.y,
          rZ: activeItem.rotation.z,
        },
      ],
    };

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(API_URL + "/VirtualStore/postElements", JSON.stringify(body), {
        headers,
      })
      .then((response) => {
        let responseItems = response.data.items[0];
        activeItem.children[0].text = responseItems.address;
        activeItem.address = responseItems.address;
        setComboboxOpened(false);
        setSelected([]);
      });
  };

  const filterElements = (event) => {
    let copyMainArray = [...copyArrayOfElements];
    if (event.target.value.length > 0) {
      let filteredArray = copyMainArray.filter((item) => {
        return item.address
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setArrayOfElements(filteredArray);
    } else {
      setArrayOfElements(copyMainArray);
    }
  };

  return selected.length === 1 ? (
    <div className="detail-window-container">
      <div className="row separator">
        <div className="label">ID:</div>
        <div className="input">{activeItem.uuid}</div>
      </div>
      <div className="row separator">
        <div className="label">Nazwa:</div>
        <div className="input">{activeItem.shape}</div>
      </div>
      <div className="row separator">
        <div className="label">Adres:</div>
        <div className="input">
          <button
            onClick={(event) => {
              showAddressesHandler(event);
            }}
          >
            {activeItem.address}
          </button>
          {comboboxOpened && (
            <div className="combo-box-container">
              <input
                type="text"
                placeholder="Szukaj..."
                onChange={(event) => filterElements(event)}
              />
              <div className="list-elements">
                {dataLoaded ? (
                  arrayOfElements.map((el, index) => {
                    return (
                      <p
                        key={index}
                        onClick={() => {
                          chooseAddressHandler(el.address);
                        }}
                      >
                        {el.address}, {el.mainSector}, {el.mainSection}
                      </p>
                    );
                  })
                ) : (
                  <div className="loader">
                    <Oval
                      color="white"
                      secondaryColor="gray"
                      width={30}
                      height={30}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row separator">
        <div className="label">Dział:</div>
        <div className="input">{activeItem.department}</div>
      </div>
      <div className="row">
        <div className="label">X:</div>
        <div className="input">
          {(activeItem.position.x + (minXGlobal / 100) * -1).toFixed(2)}m
        </div>
      </div>
      <div className="row separator">
        <div className="label">Y:</div>
        <div className="input">
          {(activeItem.position.z + (minYGlobal / 100) * -1).toFixed(2)}m
        </div>
      </div>
      {activeItem.geometry.parameters ? (
        <div className="row">
          <div className="label">Szer.:</div>
          <div className="input">
            {activeItem.geometry.parameters.width
              ? activeItem.geometry.parameters.width
              : ""}
            m
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="row">
        <div className="label">Wys.:</div>
        <div className="input">
          {activeItem.geometry.parameters
            ? activeItem.geometry.parameters.height
            : ""}
          m
        </div>
      </div>
      <div className="row separator">
        <div className="label">Gł.:</div>
        <div className="input">
          {activeItem.geometry.parameters
            ? activeItem.geometry.parameters.depth
            : ""}
          m
        </div>
      </div>
      <div className="row">
        <div className="label">Kolor</div>
        <div className="input">
          <button
            onClick={() => {
              colorPickerHandle();
            }}
            style={{
              backgroundColor: "#" + activeItem.color,
              width: "15px",
              height: "15px",
              minWidth: "unset",
            }}
          ></button>
        </div>
      </div>
    </div>
  ) : (
    <div className="detail-window-container">
      <div className="row">Ilość zaznaczonych: {selected.length}</div>
      <div className="row">X: {minX.toFixed(2) + "-" + maxX.toFixed(2)}m</div>
      <div className="row">Y: {minY.toFixed(2) + "-" + maxY.toFixed(2)}m</div>
    </div>
  );
};
