import { useEffect } from "react";
import "./RotateElements.scss";
import * as THREE from "three";
import { SaveConfigToAPI } from "../../models/SaveConfigToAPI";
export const RotateElements = (props) => {
  const { setRotateElModal, selected, shopId, sceneData } = props;
  const selectedSortedByX = selected.sort((a, b) => {
    return a.position.x - b.position.x;
  });
  const copyOfSorted = [...selectedSortedByX];
  const saveValue = () => {
    const copyOfRoted = [...selectedSortedByX];
    const input = document.querySelector("#input-rotate");
    selected.forEach((item) => {
      item.rotateY(THREE.MathUtils.degToRad(input.value));
    });
    const lenght = copyOfRoted.length;
    if (input.value === "180" && lenght > 1) {
      let queue = [];
      selectedSortedByX.forEach((item, key) => {
        queue.push(copyOfRoted[lenght - 1 - key].address);
      });
      selectedSortedByX.forEach((item, key) => {
        item.address = queue[key];
        item.userData.address = queue[key];
        item.children[0].text = queue[key];
      });
      SaveConfigToAPI({ shopId: shopId, sceneData: sceneData });
      setRotateElModal(false);
    }
  };

  useEffect(() => {
    const input = document.querySelector("#input-rotate");
    input.focus();
    input.select();
  }, []);
  return (
    <div className="rotate-elements-modal">
      <label>Obrót o: </label>
      <input
        id="input-rotate"
        type="number"
        defaultValue={0}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            saveValue();
          } else if (e.keyCode === 27) {
            setRotateElModal(false);
          }
        }}
      />
      °
    </div>
  );
};
