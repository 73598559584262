import salesman from "salesman.js";

export const TravelingSalesman2 = (props) => {
  const ExampleResponse = {
    items: [
      { uuid: "start", x: -15, y: -30, x1: 0, x2: 0, y1: 0, y2: 0 },
      { uuid: 5404, x: -5.23, y: -6.87 },
      { uuid: 5482, x: 1.41, y: -3.31 },
      { uuid: 5391, x: 10.72, y: -8.87 },
      { uuid: 5384, x: 1.41, y: -8.87 },
    ],
  };
  let itemsSM = [];

  const { items } = ExampleResponse;
  if (items && items.length > 0) {
    itemsSM.push(new salesman.Point(items[0].x, items[0].y));

    items.map((item, key) => {
      if (key > 0) {
        itemsSM.push(new salesman.Point(item.x, item.y));
      }
    });

    const solution = salesman.solve(items, 0.999995);

    const resolve = solution.map((item, key) => {
      return { uuid: items[item].uuid, x: items[item].x, y: items[item].y };
    });

    return JSON.stringify(resolve);
  }
};
