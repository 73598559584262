import { useMemo } from "react";
import * as THREE from "three";

export const SetCatalogueItems = (catalogue) => {
  let allObjects = {};
  if (catalogue && catalogue.length > 0) {
    catalogue.forEach((item) => {
      allObjects[item.name] = new THREE.BoxGeometry(
        item.width / 10,
        item.height / 10,
        item.depth / 10
      );
    });
  }
  return allObjects;
};
