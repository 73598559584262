import axios from "axios";
import { GetAllItems } from "../models/GetAllItems";
import { API_URL } from "../../env";
import { AddItemToCanvas } from "./AddItemToCanvas";

export const newCopiedElement = (
  element,
  direction,
  translateX,
  step,
  shopId,
  setObjectOnCanvas,
  setSelected,
  sceneData,
  allObjects
) => {
  // SendConfigToAPI();
  let copyOfElement = element.clone();
  copyOfElement.translateX(translateX);
  let body = {
    shopId: shopId,
    items: [
      {
        uniqueId: element.uuid,
        shapeId: element.shapeId,
        address: element.address,
        objectName: element.shape,
        color: element.color,
        x: Math.round(copyOfElement.position.x * 100),
        y: Math.round(copyOfElement.position.z * 100),
        w: element.geometry.parameters.width * 100,
        h: element.geometry.parameters.height * 100,
        addDirection: direction,
        rX: copyOfElement.rotation._x,
        rY: copyOfElement.rotation._y,
        rZ: copyOfElement.rotation._z,
        addIncrement: step,
      },
    ],
  };
  const headers = {
    "Content-Type": "application/json",
  };
  axios
    .post(API_URL + "/VirtualStore/newElement", JSON.stringify(body), {
      headers,
    })
    .then((response) => {
      response.data.items.forEach((item) => {
        AddItemToCanvas({
          setObjectOnCanvas,
          key: item.uniqueId,
          rX: item.rX,
          rY: item.rY,
          rZ: item.rZ,
          uniqueId: item.uniqueId,
          shapeId: item.shapeId,
          address: item.address,
          department: item.department,
          shape: item.objectName,
          color: item.color,
          position: [item.x / 100, item.h / 100 / 2, item.y / 100],
          shelfs: item.shelfs,
          allObjects: allObjects,
          attributes: item.attributes,
        });

        setTimeout(() => {
          const items = GetAllItems(sceneData);
          items.forEach((item) => {
            item.material.wireframe = false;
          });
          setSelected([items[items.length - 1]]);
          items[items.length - 1].updateMatrix();
        }, 150);
      });
    });
};

export function copyElementToLeft(element, props) {
  const {
    shopId,
    setObjectOnCanvas,
    setSelected,
    sceneData,
    step,
    allObjects,
  } = props;
  if (element) {
    let translateX = -element.geometry.parameters.width;
    newCopiedElement(
      element,
      "left",
      translateX,
      step,
      shopId,
      setObjectOnCanvas,
      setSelected,
      sceneData,
      allObjects
    );
  }
}

export function copyElementToRight(element, props) {
  const {
    shopId,
    setObjectOnCanvas,
    setSelected,
    sceneData,
    step,
    allObjects,
  } = props;
  if (element) {
    let translateX = element.geometry.parameters.width;
    newCopiedElement(
      element,
      "right",
      translateX,
      step,
      shopId,
      setObjectOnCanvas,
      setSelected,
      sceneData,
      allObjects
    );
  }
}
