import { BsBadge3D } from "react-icons/bs";
import { Tooltip as ReactTooltip } from "react-tooltip";
import * as THREE from "three";
import "./Switcher3D.scss";

export const Switcher3D = (props) => {
  const { controlsRef, view2D, setView2D, started2dPosition } = props;
  const change2DHandler = () => {
    if (!view2D) {
      let camera = controlsRef.current.object;
      let startedCamera = started2dPosition.object;
      const point2 = new THREE.Vector3(0, 0, 0);
      const point = startedCamera.position.clone();
      point.x = 0;
      point.z = 0;
      camera.position.copy(point);
      camera.lookAt(point2);
      controlsRef.current.target.copy(point2);
      controlsRef.current.update();
    }
    setView2D(!view2D);
  };

  return (
    <div
      className={`item3D ${view2D ? "disabled" : "active-3d"}`}
      onClick={() => change2DHandler()}
      id="3D-icon"
    >
      <BsBadge3D />
      <ReactTooltip
        anchorId="3D-icon"
        place="left"
        html="<b>Tryb 3D</b><i> <br> Jeżeli aktywne użyj prawego klawisza myszki<br>do sterowania widokiem 3D.</i>"
      />
    </div>
  );
};
