import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../env";

export const SaveMapToAPI = (objects, shopId, scaleBackground, props) => {
  const { setMapLoading } = props;

  function blobToBase64(blob) {
    axios.get(blob, { responseType: "blob" }).then((response) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        const body = {
          shopId: shopId,
          image: reader.result,
          scale: scaleBackground,
        };
        // setMapLoading(true);
        axios
          .post(API_URL + "/VirtualStore/postBackground", body)
          .then((response) => {
            if (response.data.status.success) {
              // setMapLoading(false);
              toast("Poprawnie zapisano tło", { type: "success" });
            } else {
              // setMapLoading(false);
              toast(response.data.status.message, { type: "error" });
            }
          });
      };
      reader.readAsDataURL(response.data);
    });
  }

  objects.forEach((obj) => {
    if (obj.type === "Group") {
      const children = obj.children;
      children.forEach((child) => {
        if (child.type === "Mesh") {
          if (child.uuid === "background-img-own") {
            console.log(child);
            const blobImg =
              child.children[0].material.map.source.data.currentSrc;
            blobToBase64(blobImg);
          }
        }
      });
    }
  });
};
