import "./ContextMenu.scss";
import { ContextMenuItem } from "./ContextMenuItem/ContextMenuItem";
import {
  TbColumnInsertLeft,
  TbColumnInsertRight,
  TbArrowLeftSquare,
  TbArrowRightSquare,
  TbNumbers,
  TbRotate,
} from "react-icons/tb";
import {
  BsAlignTop,
  BsAlignBottom,
  BsAlignStart,
  BsAlignEnd,
  BsAlignCenter,
  BsTrash,
} from "react-icons/bs";
import { useEffect, useRef } from "react";
import { ChooseStepComponent } from "./ContextMenuItem/ChooseStepComponent";
import { MoveElementsComponent } from "./ContextMenuItem/MoveElementsComponent";
import { IoMdCut } from "react-icons/io";

export const ContextMenu = (props) => {
  const {
    style,
    selectedItems,
    setContextMenuAction,
    contextMenu,
    step,
    setStep,
  } = props;
  const menuRef = useRef();

  useEffect(() => {
    const topLeft = menuRef.current.getBoundingClientRect();
    const canvasHeight = document.querySelector("canvas").height;
    const canvasWidth = document.querySelector("canvas").width;

    if (topLeft.y + topLeft.height > canvasHeight) {
      const menu = document.querySelector(".context-menu-container");
      menu.style.top = contextMenu.posY - topLeft.height + "px";
    }

    if (topLeft.x + topLeft.width > canvasWidth) {
      const menu = document.querySelector(".context-menu-container");
      menu.style.left = contextMenu.posX - topLeft.width + "px";
    }
  }, []);

  return (
    <div ref={menuRef} style={style} className="context-menu-container">
      <div>
        <ContextMenuItem
          icon={<TbColumnInsertLeft />}
          name="Kopiuj element w lewo"
          disabled={selectedItems === 1 ? false : true}
          additional={
            <ChooseStepComponent
              step={step}
              setStep={setStep}
              setContextMenuAction={setContextMenuAction}
              setContextMenuActionValue="copyToLeft"
            />
          }
        />
      </div>
      <div>
        <ContextMenuItem
          icon={<TbColumnInsertRight />}
          name="Kopiuj element w prawo"
          disabled={selectedItems === 1 ? false : true}
          additional={
            <ChooseStepComponent
              step={step}
              setStep={setStep}
              setContextMenuAction={setContextMenuAction}
              setContextMenuActionValue="copyToRight"
            />
          }
        />
      </div>
      <div>
        <ContextMenuItem
          icon={<TbArrowLeftSquare />}
          name="Przesuń element w lewo"
          disabled={false}
          additional={
            <MoveElementsComponent
              setContextMenuAction={setContextMenuAction}
              contextMenuValue={"moveToLeft"}
            />
          }
        />
      </div>

      <div>
        <ContextMenuItem
          icon={<TbArrowRightSquare />}
          name="Przesuń element w prawo"
          disabled={false}
          additional={
            <MoveElementsComponent
              setContextMenuAction={setContextMenuAction}
              contextMenuValue={"moveToRight"}
            />
          }
        />
      </div>

      <div
        onClick={() => {
          setContextMenuAction("alignToEdges");
        }}
      >
        <ContextMenuItem
          icon={<BsAlignCenter />}
          name="Wyrównaj do środkowych krawędzi"
          disabled={selectedItems === 2 ? false : true}
        />
      </div>

      <div
        onClick={() => {
          setContextMenuAction("alignToTop");
        }}
      >
        <ContextMenuItem
          icon={<BsAlignTop />}
          name="Wyrównaj do górnej krawędzi"
          disabled={selectedItems > 1 ? false : true}
        />
      </div>

      <div
        onClick={() => {
          setContextMenuAction("alignToBottom");
        }}
      >
        <ContextMenuItem
          icon={<BsAlignBottom />}
          name="Wyrównaj do dolnej krawędzi"
          disabled={selectedItems > 1 ? false : true}
        />
      </div>

      <div
        onClick={() => {
          setContextMenuAction("alignToLeft");
        }}
      >
        <ContextMenuItem
          icon={<BsAlignStart />}
          name="Wyrównaj do lewej krawędzi"
          disabled={selectedItems > 1 ? false : true}
        />
      </div>

      <div
        onClick={() => {
          setContextMenuAction("alignToRight");
        }}
      >
        <ContextMenuItem
          icon={<BsAlignEnd />}
          name="Wyrównaj do prawej krawędzi"
          disabled={selectedItems > 1 ? false : true}
        />
      </div>
      <div
        onClick={() => {
          setContextMenuAction("changeAddresses");
        }}
      >
        <ContextMenuItem
          icon={<TbNumbers />}
          name="Zmień masowo adresację"
          disabled={selectedItems > 1 ? false : true}
        />
      </div>

      <div
        onClick={() => {
          setContextMenuAction("splitElements");
        }}
      >
        <ContextMenuItem
          icon={<IoMdCut />}
          name="Podziel element"
          disabled={selectedItems > 0 ? false : true}
        />
      </div>

      <div
        onClick={() => {
          setContextMenuAction("rotateElements");
        }}
      >
        <ContextMenuItem
          icon={<TbRotate />}
          name="Odwróć zaznaczone elementy"
          disabled={selectedItems > 0 ? false : true}
        />
      </div>

      <div
        onClick={() => {
          setContextMenuAction("deleteItem");
        }}
      >
        <ContextMenuItem
          icon={<BsTrash />}
          name="Usuń element"
          disabled={false}
        />
      </div>
    </div>
  );
};
