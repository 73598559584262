import React from "react";
import "./AddNewItemModal.scss";
import { API_URL } from "../../../env";
import axios from "axios";
import { Suspense } from "react";
import { AddItemToCanvas } from "../../models/AddItemToCanvas";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { ActionsHistory } from "../../ActionsHistory/ActionsHistory";

export const AddNewItemModal = (props) => {
  const {
    controlsRef,
    setObjectOnCanvas,
    catalogueRows,
    allObjects,
    setAddItemModalOpen,
    transformControlsMode,
  } = props;

  const { shopId } = useParams();
  let posX;
  let posY;
  if (
    !isNaN(controlsRef.current.target.x) &&
    !isNaN(controlsRef.current.target.z)
  ) {
    posX = Number(controlsRef.current.target.x * 100).toFixed(0);
    posY = Number(controlsRef.current.target.z * 100).toFixed(0);
  } else {
    posX = 0;
    posY = 0;
  }
  const addObjectToCanvasHandler = (element) => {
    let body = {
      shopId: shopId,
      items: [
        {
          uniqueId: 0,
          shapeId: element.shapeId,
          objectName: element.name,
          color: element.color,
          x: posX,
          y: posY,
          w: element.width,
          h: element.height,
          rX: 0,
          rY: 0,
          rZ: 0,
        },
      ],
    };

    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(API_URL + "/VirtualStore/newElement", JSON.stringify(body), {
        headers,
      })
      .then((result) => {
        result.data.items.forEach((item) => {
          AddItemToCanvas({
            setObjectOnCanvas,
            key: item.uniqueId,
            rX: item.rX,
            rY: item.rY,
            rZ: item.rZ,
            uniqueId: item.uniqueId,
            shapeId: item.shapeId,
            address: transformControlsMode === "scale" ? "SKALA" : item.address,
            department: item.department,
            shape: item.objectName,
            color: transformControlsMode === "scale" ? "9F2B68" : item.address,
            position: [item.x / 100, item.h / 100 / 2, item.y / 100],
            shelfs: item.shelfs,
            allObjects: allObjects,
            attributes: item.attributes,
          });
        });
        toast("Poprawnie dodano element", { type: "success" });
        ActionsHistory({
          action: {
            type: "add",
            items: result.data.items,
            timestamp: Date.now(),
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
    props.setAddItemModalOpen(false);
  };

  return (
    <div className="add-new-item-modal-container">
      <div
        className="close-modal-icon"
        onClick={() => {
          setAddItemModalOpen(false);
        }}
      >
        <IoMdClose />
      </div>
      <Suspense fallback={<h1>Ładowanie...</h1>}>
        <div className="list-container">
          {catalogueRows.map((el, index) => {
            if (el.visible) {
              return (
                <div
                  key={index}
                  className={`item`}
                  onClick={() => addObjectToCanvasHandler(el)}
                >
                  {el.name}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </Suspense>
    </div>
  );
};
