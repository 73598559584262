import { toast } from "react-toastify";
import "./ChangeAddressModal.scss";
import axios from "axios";
import { API_URL } from "../../../env";

export const ChangeAddressModal = (props) => {
  const { setChangeAddressModal, selected, setSelected, shopId } = props;

  const sendUpdateToAPI = (items) => {
    const body = {
      shopId: shopId,
      items: items,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(API_URL + "/VirtualStore/postElements", JSON.stringify(body), {
        headers,
      })
      .then((response) => {
        const items = response.data.items;
        items.forEach((item, key) => {
          selected.forEach((selectedItem) => {
            if (selectedItem.uniqueId === item.uniqueId) {
              selectedItem.children[0].text = item.address;
              selectedItem.address = item.address;
              selectedItem.userData.address = item.address;
              selectedItem.updateMatrix();
            }
          });
        });
        setChangeAddressModal(false);
        setSelected([]);
        toast("Poprawnie zmieniono adres", { type: "success" });
      });
  };

  const saveValue = () => {
    const inputZ = document.querySelector("#input-z");
    const inputNa = document.querySelector("#input-na");
    if (selected.length > 0) {
      let items = [];
      selected.forEach((item) => {
        items.push({
          uniqueId: item.uuid,
          address: item.address.replace(inputZ.value, inputNa.value),
          shapeId: item.shapeId,
          objectName: item.shape,
          color: item.color,
          x: Math.round(Number(item.position.x * 100)),
          y: Math.round(Number(item.position.z * 100)),
          w: item.geometry.parameters.width * 100,
          h: item.geometry.parameters.height * 100,
          rX: item.rotation.x,
          rY: item.rotation.y,
          rZ: item.rotation.z,
        });
      });
      sendUpdateToAPI(items);
    }
  };
  return (
    <div className="change-address-modal-container">
      <label>Zamień frazę</label>
      <input
        type="text"
        id="input-z"
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            saveValue();
          } else if (e.keyCode === 27) {
            setChangeAddressModal(false);
          }
        }}
      />{" "}
      na{" "}
      <input
        type="text"
        id="input-na"
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            saveValue();
          } else if (e.keyCode === 27) {
            setChangeAddressModal(false);
          }
        }}
      />
    </div>
  );
};
