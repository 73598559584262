import axios from "axios";
import { API_URL } from "../../env";
import { toast } from "react-toastify";

export function deleteObject(element, props) {
  const { shopId, objectOnCanvas, setObjectOnCanvas, setSelected } = props;

  let items = [];
  element.forEach((item) => {
    items.push({ uniqueId: item.uuid });
  });
  let headers = {
    "Content-Type": "application/json",
  };
  let body = {
    shopId: shopId,
    items: items,
  };

  axios
    .post(API_URL + "/VirtualStore/deleteElements", JSON.stringify(body), {
      headers,
    })
    .then((response) => {
      if (response.status === 200) {
        let copyArray = objectOnCanvas;
        element.forEach((el) => {
          copyArray = _.remove(copyArray, (i) => {
            return i.props.uniqueId !== el.uniqueId;
          });
        });
        setObjectOnCanvas(copyArray);
        setSelected([]);
        toast("Poprawnie usunięto", { type: "success" });
      }
    });
}
