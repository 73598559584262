import React, { useMemo } from "react";
import "./App.scss";
import * as THREE from "three";
import { Canvas, useThree } from "@react-three/fiber";

import {
  Plane,
  Select,
  TransformControls,
  MapControls,
  Grid,
  useTexture,
  Stats,
  Line,
} from "@react-three/drei";
import { useEffect, useRef, useState, Suspense } from "react";
import { RightMenu } from "./components/RightMenu/RightMenu";
import { RightMenuOpener } from "./components/RightMenu/RightMenuOpener";
import { LeftMenuComponent } from "./components/LeftMenu/LeftMenuComponent";
import { LeftMenuOpener } from "./components/LeftMenu/LeftMenuOpener";
import { RightToolbar } from "./components/RightToolbar/RightToolbar";
import { AddNewItemModal } from "./components/Modal/AddNewItem/AddNewItemModal";
import axios from "axios";
import { API_URL } from "./env";
import { AddItemToCanvas } from "./components/models/AddItemToCanvas";
import { BottomSummaryBar } from "./components/BottomSummaryBar/BottomSummaryBar";
import { FilterModal } from "./components/Modal/FilterModal/FilterModal";
import { useParams } from "react-router-dom";
import { ContextMenu } from "./components/ContextMenu/ContextMenu";
import {
  copyElementToLeft,
  copyElementToRight,
} from "./components/models/CopingElements";
import { deleteObject } from "./components/models/DeleteObject";
import { MoveElement } from "./components/models/MovingElements";
import { Aligment } from "./components/models/Aligment";
import { TopBar } from "./components/TopBar/TopBar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isNumber } from "lodash";
import { SaveConfigToAPI } from "./components/models/SaveConfigToAPI";
import { CopingGroupElements } from "./components/models/CopingGroupElements";
import { SetAddressModal } from "./components/Modal/SetAddressModal/SetAddressModal";
import { ChangeAddressModal } from "./components/Modal/ChangeAddressModal/ChangeAddressModal";
import { EditButton } from "./components/EditButton";
import { Switcher3D } from "./components/Switcher3D";
import { RotateElements } from "./components/Modal/RotateElements/RotateElements";
import { SaveMapToAPI } from "./components/models/SaveMapToAPI";
import { LoaderComponent } from "./components/Modal/Loader/LoaderComponent";
// import { TravelingSalesman } from "./components/TravelingSalesman/TravelingSalesman";
import { ScaleBackgroundModal } from "./components/Modal/ScaleBackgroundModal/ScaleBackgroundModal";
import { SplitModal } from "./components/Modal/SplitModal/SplitModal";
import { RightAdvancedMenu } from "./components/RightAdvancedMenu/RightAdvancedMenu";
import { HelpModal } from "./components/Modal/HelpModal/HelpModal";

// function Instances({ count = 50000, temp = new THREE.Object3D() }) {
//   const ref = useRef();
//   useEffect(() => {
//     // Ustawienie pozycji
//     for (let i = 0; i < count; i++) {
//       temp.position.set(Math.random() * 500, 2, Math.random() * 500);
//       temp.updateMatrix();
//       ref.current.setMatrixAt(i, temp.matrix);
//     }
//     // Aktualizacja instancji
//     ref.current.instanceMatrix.needsUpdate = true;
//   }, []);
//   return (
//     <instancedMesh ref={ref} args={[null, null, count]} uuid={"1234"}>
//       <boxGeometry />
//       <meshPhongMaterial color={"red"} />
//       <Line
//         color="black"
//         lineWidth={1}
//         opacity={0.5}
//         points={[
//           [-1 / 2 + 0.01, -1 / 2, 1 / 2 + 0.0005],
//           [1 / 2 - 0.01, -1 / 2, 1 / 2 + 0.0005],
//         ]}
//       />
//     </instancedMesh>
//   );
// }

function App() {
  const [rightMenuOpened, setRightMenuOpened] = useState(false);
  const [leftMenuOpened, setLeftMenuOpened] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState();
  const [backgroundWidth, setBackgroundWidth] = useState(500);
  const [backgroundHeight, setBackgroundHeight] = useState(500);
  const [selected, setSelected] = useState([]);
  const [objectOnCanvas, setObjectOnCanvas] = useState([]);
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [transformControlsMode, setTransformControlsMode] =
    useState("translate");
  const [rotate15degMode, setRotate15degMode] = useState(true);
  const [snapRotateNumber, setSnapRotateNumber] = useState();
  const [mapDataLoaded, setMapDataLoaded] = useState(false);
  const [showX, setShowX] = useState(true);
  const [showY, setShowY] = useState(true);
  const [showZ, setShowZ] = useState(true);
  const active = selected[0];
  const canvasRef = useRef();
  const controlsRef = useRef();
  const [sceneData, setSceneData] = useState();
  const appRender = useRef(0);
  const [itemEdited, setItemEdited] = useState(false);
  const [view2D, setView2D] = useState(true);
  const [filterModal, setFilterModal] = useState(null);
  const [dataType, setDataType] = useState();
  const [stepModal, setStepModal] = useState(false);
  const [contextMenu, setContextMenu] = useState({});
  const [contextMenuAction, setContextMenuAction] = useState();
  const [shopName, setShopName] = useState();
  const [step, setStep] = useState(1);
  const [showGrid, setShowGrid] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [defaultAddressModal, setDefaultAddressModal] = useState();
  const [changeAddressModal, setChangeAddressModal] = useState(false);
  const [splitElementModal, setSplitElementModal] = useState(false);
  const [catalogueRows, setCatalogueRows] = useState();
  const copyElements = useRef();
  const [allObjects, setAllObjects] = useState();
  const [rotateElModal, setRotateElModal] = useState();
  const [blocks, setBlocks] = useState();
  const [scaleBackgroundModal, setScaleBackgroundModal] = useState(false);
  const [scaleBackground, setScaleBackground] = useState(1);
  const [scaleBackgroundMode, setScaleBackgroundMode] = useState(false);
  const [myZoom, setMyZoom] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const zoom = useRef();
  const [zones, setZones] = useState([]);
  const [mapLoading, setMapLoading] = useState(false);
  const [advancedMenuActive, setAdvancedMenuActive] = useState(false);
  const [started2dPosition, setStarted2dPosition] = useState();
  const [helpModalActive, setHelpModalActive] = useState(false);
  const [pastedElements, setPastedElements] = useState([]);
  const [scaleModal, setScaleModal] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [mapSettingPosition, setMapSettingPosition] = useState(false);

  const mapRotation = useRef();

  appRender.current++;

  const { shopId } = useParams();
  const { uids } = useParams();
  const arrayUids = uids.split(",");
  const { editMode } = useParams();
  const { selectedId } = useParams();
  const { zoneId } = useParams();

  function TexturedPlane() {
    const planeRef = useRef();
    const texture = useTexture(
      backgroundImage ? backgroundImage : "img/empty.jpg"
    );
    const material = new THREE.MeshBasicMaterial({
      color: "white",
      map: texture,
    });
    material.side = THREE.DoubleSide;

    if (backgroundImage) {
      return (
        <mesh
          ref={planeRef}
          position={[0, 0, 0]}
          onClick={() => setScaleBackgroundModal(true)}
          uuid={"background-img-own"}
          needsUpdate={true}
          matrixWorldNeedsUpdate={true}
        >
          <Plane
            args={[backgroundWidth / 50, backgroundHeight / 50]}
            rotation={[-Math.PI / 2, 0, 0]}
            material={material}
            scale={scaleBackground}
            onClick={(event) => {
              setMapSettingPosition(planeRef.current);
            }}
          />
        </mesh>
      );
    } else {
      return null;
    }
  }

  const getCatalogueRows = () => {
    axios.get(API_URL + "/VirtualStore/getCatalogue").then((response) => {
      if (response.data.status.success) {
        const data = response.data.catalogueRows;
        setCatalogueRows(response.data.catalogueRows);
        let allObjects2 = {};
        data.forEach((item) => {
          if (editMode === "2") {
            allObjects2[item.name] = new THREE.BoxGeometry(
              item.width / 100,
              item.height / 100,
              item.depth / 100
            );
          } else {
            allObjects2[item.name] = new THREE.BoxGeometry(
              item.width / 100 - response.data.gapWidth,
              item.height / 100,
              item.depth / 100 - response.data.gapLength
            );
          }
        });
        setAllObjects(allObjects2);
      } else {
        toast(response.data.status.message, { type: "error" });
      }
    });
  };

  const Test = (scene) => {
    const state = useThree((state) => state.scene);
    if (selected.length === 0 && state.children) {
      state.children.forEach((el) => {
        if (el.type === "Group") {
          let groupItems = el.children;
          groupItems.forEach((mesh) => {
            if (mesh.material.wireframe) {
              mesh.material.wireframe = false;
            }
          });
        }
      });
    }
    // console.log(state);

    // console.log("Scene polycount:", state.gl.info.render.triangles);
    // console.log("Active Drawcalls:", state.gl.info.render.calls);
    // console.log("Textures in Memory", state.gl.info.memory.textures);
    // console.log("Geometries in Memory", state.gl.info.memory.geometries);

    setSceneData(state);
    scene = state;
  };

  useEffect(() => {
    getCatalogueRows();
  }, []);

  useEffect(() => {
    let body;
    if (uids !== "0") {
      body = {
        mapAlso: false,
        shopId: shopId,
        zoneId: zoneId,
        uids: arrayUids,
      };
    } else {
      body = {
        shopId: shopId,
      };
    }

    let headers = {
      "Content-Type": "application/json",
    };
    if (!mapDataLoaded && sceneData && allObjects) {
      let minX;
      let maxX;
      let minY;
      let maxY;
      let color;

      axios
        .post(API_URL + "/VirtualStore/map", JSON.stringify(body), {
          headers,
        })
        .then((response) => {
          if (response.data.status.success) {
            if (response.data.items) {
              setShopName(response.data.shopName);
              setBackgroundImage(response.data.image);
              setScaleBackground(response.data.scale);
              setBlocks(response.data.blocks);
              setZones(response.data.zones);
              if (response.data.items && response.data.items.length > 0) {
                const groupedRotation = _.groupBy(
                  response.data.items,
                  (o, key) => o.rZ
                );
                const keys = Object.keys(groupedRotation);
                let maxKeyLength = 0;
                let maxKey;
                keys.forEach((item) => {
                  if (groupedRotation[item].length > maxKeyLength) {
                    maxKeyLength = groupedRotation[item].length;
                    maxKey = groupedRotation[item];
                  }
                });
                const cameraRotation = Math.round(
                  THREE.MathUtils.radToDeg(
                    maxKey[0].rZ !== 0 ? maxKey[0].rZ : maxKey[0].rY
                  )
                );
                mapRotation.current = cameraRotation;

                response.data.items.forEach((item) => {
                  // MIN X
                  if (minX) {
                    if (minX > item.x) {
                      minX = item.x;
                    }
                  } else {
                    minX = item.x;
                  }
                  // MAX X
                  if (maxX) {
                    if (maxX < item.x) {
                      maxX = item.x;
                    }
                  } else {
                    maxX = item.x;
                  }
                  // MIN Y
                  if (minY) {
                    if (minY > item.y) {
                      minY = item.y;
                    }
                  } else {
                    minY = item.y;
                  }
                  // MAX Y
                  if (maxY) {
                    if (maxY < item.y) {
                      maxY = item.y;
                    }
                  } else {
                    maxY = item.y;
                  }
                  if (Number(selectedId) === item.uniqueId) {
                    color = "000000";
                  } else {
                    color = item.color;
                  }

                  AddItemToCanvas({
                    setObjectOnCanvas,
                    catalogue: catalogueRows,
                    key: item.uniqueId,
                    rX: item.rX,
                    rY: item.rY,
                    rZ: item.rZ,
                    uniqueId: item.uniqueId,
                    shapeId: item.shapeId,
                    address: item.address,
                    department: item.department,
                    shape: item.objectName,
                    color: color,
                    position: [
                      item.x / 100,
                      item.z / 100 + item.h / 100 / 2,
                      item.y / 100,
                    ],
                    shelfs: item.shelfs,
                    allObjects: allObjects,
                    editMode: editMode,
                    rotation: item.rotation,
                    controlsRef: controlsRef.current,
                    x1: item.x1_frame,
                    x2: item.x2_frame,
                    y1: item.y1_frame,
                    y2: item.y2_frame,
                    attributes: item.attributes,
                  });
                });
              }
            }
          } else {
            toast(response.data.status.message);
          }
        })
        .then(() => {
          if (controlsRef) {
            let camera = controlsRef.current.object;
            if (camera.position) {
              let pointX = (minX + maxX) / 2 / 100;
              let pointY = (minY + maxY) / 2 / 100;
              const point = new THREE.Vector3(
                isNaN(pointX) ? 0 : pointX,
                100,
                isNaN(pointY) ? 0 : pointY
              );
              const point2 = new THREE.Vector3(
                isNaN(pointX) ? 0 : pointX,
                0,
                isNaN(pointY) ? 0 : pointY
              );
              camera.position.copy(point);
              camera.lookAt(point.x, 0, -point.y);
              if (editMode === "0") {
                if (Math.abs(mapRotation.current) === 0) {
                  camera.up.set(0, 1, 0);
                } else if (mapRotation.current === 90) {
                  camera.up.set(-1, 0, 0);
                } else if (Math.abs(mapRotation.current) === 180) {
                  camera.up.set(0, -1, 0);
                } else if (mapRotation.current === -90) {
                  camera.up.set(1, 0, 0);
                }
              }

              camera.updateProjectionMatrix();
              camera.updateMatrix();
              zoom.current = Math.min(
                (camera.left * 2) / (maxX - minY),
                (camera.top * 2) / (maxY - minY)
              );
              if (zoom.current < 0) {
                zoom.current = zoom.current * 55 * -1;
              } else {
                zoom.current = 100;
              }
              setMyZoom(zoom.current);
              camera.zoom = zoom.current;
              controlsRef.current.target.copy(point2);
              controlsRef.current.update();
              setStarted2dPosition(controlsRef.current);
              setMapDataLoaded(true);
            }
          }
        });
    }
  }, [mapDataLoaded, allObjects]);

  useEffect(() => {
    if (transformControlsMode === "translate") {
      setShowX(true);
      setShowY(true);
      setShowZ(true);
    }
    if (transformControlsMode === "rotate") {
      setShowX(false);
      setShowY(true);
      setShowZ(false);
    }
    if (transformControlsMode === "scale") {
      setShowX(true);
      setShowY(false);
      setShowZ(false);
    }
  }, [transformControlsMode]);

  useEffect(() => {
    if (backgroundImage) {
      const image = document.createElement("img");
      image.src = backgroundImage;
      image.onload = function () {
        setBackgroundWidth(image.width);
        setBackgroundHeight(image.height);
      };
    }
  }, [backgroundImage, selected]);

  // Dlaczego zapisywać mapę do API po każdej zmianie? //

  // useEffect(() => {
  //   if (backgroundImage) {
  //     SaveMapToAPI(sceneData.children, shopId, scaleBackground, {
  //       setMapDataLoaded: setMapDataLoaded,
  //       setMapLoading: setMapLoading,
  //     });
  //   }
  // }, [backgroundImage, scaleBackground]);

  if (itemEdited === true) {
    window.onbeforeunload = (event) => {
      event.preventDefault();
      return (event.returnValue = "Nie zapisałeś zmian! Czy chcesz wyjść?");
    };
  } else {
    window.onbeforeunload = null;
  }

  const setMapSelected = (event) => {
    if (editMode === "2" || editMode === "1") {
      if (event.length > 0) {
        setSelected(event);
      }
    }
  };

  const setSelectedHandle = (event) => {
    console.log(event[0]);
    if (editMode === "2" || editMode === "1" || editMode === "0") {
      let copySelected = [];
      let uuidSelected = [];
      if (event.length > 0) {
        event.forEach((item) => {
          if (item.geometry.parameters && isNumber(item.uuid) && item.visible) {
            if (item.material) {
              item.material.wireframe = true;
            }
            copySelected.push(item);
            uuidSelected.push(item.uniqueId);
          }
        });
      } else if (event.length === 0) {
        copySelected = [];
      }

      if (event.length === 1 && selected.length === 0) {
        if (
          event[0].parent &&
          event[0].parent.type === "Mesh" &&
          isNumber(event[0].parent.uuid) &&
          event[0].geometry.type !== "PlaneGeometry" &&
          event[0].visible &&
          event[0].parent.visible
        ) {
          if (event[0].parent.material && event[0].parent.visible) {
            event[0].parent.material.wireframe = true;
          }
          copySelected = [event[0].parent];
          setShowAddressModal(true);
          setDefaultAddressModal(event[0].parent.address);
        }
      }
      setSelected(copySelected);
      // console.log(uuidSelected.join(","));
    }
  };

  if (active && transformControlsMode === "rotate" && rotate15degMode) {
    const roundedX = Math.round(THREE.MathUtils.radToDeg(active.rotation._x));
    const roundedY = Math.round(THREE.MathUtils.radToDeg(active.rotation._y));
    const roundedZ = Math.round(THREE.MathUtils.radToDeg(active.rotation._z));

    active.rotation._x = THREE.MathUtils.degToRad(roundedX);
    active.rotation._y = THREE.MathUtils.degToRad(roundedY);
    active.rotation._z = THREE.MathUtils.degToRad(roundedZ);
  }

  // Skalowanie mapy:
  if (transformControlsMode === "scale") {
    if (active) {
      // active.scale.y = active.scale.x;
      active.scale.z = active.scale.x;
    }
  }

  useEffect(() => {
    if (rotate15degMode) {
      setSnapRotateNumber(15);
    } else {
      setSnapRotateNumber(1);
    }
  }, [rotate15degMode]);

  useEffect(() => {
    if (dataType) {
      const body = {
        shopId: shopId,
        dataType: dataType,
      };
      const headers = {
        "Content-Type": "application/json",
      };
      setDataLoading(true);
      setDataType(null);
      axios
        .post(API_URL + "/VirtualStore/getData", JSON.stringify(body), {
          headers,
        })
        .then((response) => {
          const responseData = response.data.items;
          const currentScene = sceneData.children[0].children;
          responseData.forEach((item) => {
            currentScene
              .filter((el) => el.uuid === item.uniqueId)
              .forEach((el) => {
                el.material.color = new THREE.Color(item.dataColor);
                el.material.emissive = new THREE.Color(item.dataColor);
                el.children[0].text =
                  item.uniqueId +
                  "\n" +
                  item.dataValue +
                  " (" +
                  item.dataPercent +
                  "%)";
              });
          });
          setDataLoading(false);
        });
    }
  }, [dataType]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.type === "click") {
        if (e.target.tagName === "CANVAS") {
          setContextMenu({ show: false });
          setShowAddressModal(false);
        }
      }
    });
  }, []);
  useEffect(() => {
    if (contextMenuAction === "copyToLeft") {
      copyElementToLeft(...selected, {
        shopId: shopId,
        setObjectOnCanvas: setObjectOnCanvas,
        setSelected: setSelected,
        sceneData: sceneData,
        step: step,
        allObjects: allObjects,
      });
      setContextMenuAction(null);
    }

    if (contextMenuAction === "copyToRight") {
      copyElementToRight(...selected, {
        shopId: shopId,
        setObjectOnCanvas: setObjectOnCanvas,
        setSelected: setSelected,
        sceneData: sceneData,
        step: step,
        allObjects: allObjects,
      });
      setContextMenuAction(null);
    }

    if (contextMenuAction === "deleteItem") {
      deleteObject(selected, {
        shopId: shopId,
        objectOnCanvas: objectOnCanvas,
        setObjectOnCanvas: setObjectOnCanvas,
        setSelected: setSelected,
      });
      setContextMenuAction(null);
    }

    if (contextMenuAction && contextMenuAction.type === "moveToLeft") {
      MoveElement(selected, "left", contextMenuAction.value);
      setContextMenuAction(null);
    }

    if (contextMenuAction && contextMenuAction.type === "moveToRight") {
      MoveElement(selected, "right", contextMenuAction.value);
      setContextMenuAction(null);
    }

    if (contextMenuAction === "alignToEdges") {
      Aligment(selected, "edges");
      setContextMenuAction(null);
    }

    if (contextMenuAction === "alignToTop") {
      Aligment(selected, "top");
      setContextMenuAction(null);
    }

    if (contextMenuAction === "alignToBottom") {
      Aligment(selected, "bottom");
      setContextMenuAction(null);
    }

    if (contextMenuAction === "alignToLeft") {
      Aligment(selected, "left");
      setContextMenuAction(null);
    }

    if (contextMenuAction === "alignToRight") {
      Aligment(selected, "right");
      setContextMenuAction(null);
    }

    if (contextMenuAction === "changeAddresses") {
      setChangeAddressModal(true);
      setContextMenuAction(null);
      setContextMenu({ show: false });
    }

    if (contextMenuAction === "rotateElements") {
      setRotateElModal(selected);
      setContextMenuAction(null);
      setContextMenu({ show: false });
    }

    if (contextMenuAction === "splitElements") {
      setSplitElementModal(true);
      setContextMenuAction(null);
      setContextMenu({ show: false });
    }

    if (contextMenuAction === "reloadCatalogue") {
      getCatalogueRows();
      setContextMenuAction(null);
    }
  }, [contextMenuAction]);

  window.addEventListener("resize", onWindowResize, false);

  function onWindowResize() {
    const appContainer = document.querySelector(".App");
    appContainer.style.width = window.innerWidth + "px";
    appContainer.style.height = window.innerHeight + "px";
  }

  document.onkeydown = checkKey;
  function checkKey(e) {
    let step = 0.01;
    if (e.keyCode === 38) {
      moveItems("up", step);
    }

    // COPY ELEMENTS:
    if (e.keyCode === 67) {
      if (e.ctrlKey) {
        copyElements.current = selected;
      }
    }

    // PASTE ELEMENTS:
    if (e.keyCode === 86) {
      if (e.ctrlKey) {
        CopingGroupElements(copyElements.current, {
          shopId: shopId,
          setObjectOnCanvas: setObjectOnCanvas,
          allObjects: allObjects,
          sceneData: sceneData,
          setSelected: setSelected,
          setPastedElements: setPastedElements,
        });
      }
    }

    // SAVE OBJECTS:
    if (e.keyCode === 83) {
      e.preventDefault();
      if (e.ctrlKey) {
        SaveConfigToAPI({
          shopId: shopId,
          sceneData: sceneData,
          setItemEdited: setItemEdited,
          setMapDataLoaded: setMapDataLoaded,
        });
        SaveMapToAPI(sceneData.children, shopId, scaleBackground, {
          setMapDataLoaded: setMapDataLoaded,
          setMapLoading: setMapLoading,
        });
      }
    }

    // DELETE OBJECTS
    if (e.keyCode === 46) {
      deleteObject(selected, {
        shopId: shopId,
        objectOnCanvas: objectOnCanvas,
        setObjectOnCanvas: setObjectOnCanvas,
        setSelected: setSelected,
      });
    } else if (e.keyCode === 40) {
      moveItems("down", step);
    } else if (e.keyCode === 37) {
      moveItems("left", step);
    } else if (e.keyCode === 39) {
      moveItems("right", step);
    }

    if (e.keyCode === 38 && e.ctrlKey) {
      moveItems("up", 0.1);
    } else if (e.keyCode === 40 && e.ctrlKey) {
      moveItems("down", 0.1);
    } else if (e.keyCode === 37 && e.ctrlKey) {
      moveItems("left", 0.1);
    } else if (e.keyCode === 39 && e.ctrlKey) {
      moveItems("right", 0.1);
    }

    if (e.keyCode === 38 && e.shiftKey) {
      moveItems("up", 1);
    } else if (e.keyCode === 40 && e.shiftKey) {
      moveItems("down", 1);
    } else if (e.keyCode === 37 && e.shiftKey) {
      moveItems("left", 1);
    } else if (e.keyCode === 39 && e.shiftKey) {
      moveItems("right", 1);
    }

    // CTRL + Z
    if (e.keyCode === 90 && e.ctrlKey) {
      deleteObject(pastedElements, {
        shopId: shopId,
        objectOnCanvas: objectOnCanvas,
        setObjectOnCanvas: setObjectOnCanvas,
        setSelected: setSelected,
      });
    }

    // ALT + S: Zapis pozycji mapy
    if (e.keyCode === 83 && e.altKey) {
      let camera = controlsRef.current.object;
      const body = {
        position: camera.position,
      };
      axios
        .post(API_URL + "/VirtualStore/postElements", body)
        .then((res) => console.log(res));
    }
  }

  const moveItems = (direction, step) => {
    if (direction === "up") {
      selected.forEach((item) => {
        item.position.set(
          item.position.x,
          item.position.y,
          item.position.z - step
        );
        item.updateMatrix();
      });
    }
    if (direction === "down") {
      selected.forEach((item) => {
        item.position.set(
          item.position.x,
          item.position.y,
          item.position.z + step
        );
        item.updateMatrix();
      });
    }
    if (direction === "left") {
      selected.forEach((item) => {
        item.position.set(
          item.position.x - step,
          item.position.y,
          item.position.z
        );
        item.updateMatrix();
      });
    }
    if (direction === "right") {
      selected.forEach((item) => {
        item.position.set(
          item.position.x + step,
          item.position.y,
          item.position.z
        );
        item.updateMatrix();
      });
    }
  };

  const wheelHandler = () => {
    if (controlsRef.current && controlsRef.current.object.zoom) {
      setMyZoom(controlsRef.current.object.zoom);
    }
  };

  useEffect(() => {
    if (selected.length > 0 && editMode !== "0") {
      setAdvancedMenuActive(true);
    } else {
      setAdvancedMenuActive(false);
    }

    if (selected && selected.length > 0) {
      selected.forEach((item) => {
        item.material.wireframe = true;
      });
    }
  }, [selected]);

  const saveScale = () => {
    const AllItems = sceneData.children[0].children;
    let scaleElement = [];
    let scaleCalc = 1;
    AllItems.forEach((item) => {
      if (item.address === "SKALA") {
        scaleElement.push(item);
      }
    });

    scaleElement.forEach((item) => {
      if (item.scale.x !== 1) {
        if (item.scale.x > 1) {
          scaleCalc = 1 / item.scale.x;
        } else {
          scaleCalc = 1 + item.scale.x;
        }
      }
    });

    if (scaleCalc) {
      setScaleBackground(scaleCalc);
      SaveMapToAPI(sceneData.children, shopId, scaleCalc, {
        setMapDataLoaded: setMapDataLoaded,
        setMapLoading: setMapLoading,
      });
      deleteObject(scaleElement, {
        shopId: shopId,
        objectOnCanvas: objectOnCanvas,
        setObjectOnCanvas: setObjectOnCanvas,
        setSelected: setSelected,
      });
      setScaleModal(false);
      setAddItemModalOpen(false);
      setTransformControlsMode("translate");
    }
  };

  // console.log("Render", appRender.current);
  return (
    <div className="App">
      {!mapDataLoaded && <LoaderComponent />}
      {mapLoading ? <LoaderComponent /> : ""}
      {dataLoading ? <LoaderComponent /> : ""}

      {editMode === "1" && (
        <>
          <LeftMenuOpener setLeftMenuOpened={setLeftMenuOpened} />
          <LeftMenuComponent
            leftMenuOpened={leftMenuOpened}
            setLeftMenuOpened={setLeftMenuOpened}
            setAddItemModalOpen={setAddItemModalOpen}
            sceneData={sceneData}
            setFilterModal={setFilterModal}
            setDataType={setDataType}
          />
          <EditButton editMode={editMode} shopId={shopId} />
        </>
      )}

      {editMode !== "0" && (
        <>
          <Switcher3D
            controlsRef={controlsRef}
            view2D={view2D}
            setView2D={setView2D}
            started2dPosition={started2dPosition}
          />
          <TopBar shopname={shopName} zones={zones} />
        </>
      )}

      {editMode === "2" && (
        <>
          {contextMenu.show ? (
            <ContextMenu
              style={{ top: contextMenu.posY, left: contextMenu.posX }}
              selectedItems={selected.length}
              contextMenu={contextMenu}
              setContextMenuAction={setContextMenuAction}
              step={step}
              setStep={setStep}
            />
          ) : (
            ""
          )}
          <RightToolbar
            view2D={view2D}
            setView2D={setView2D}
            controlsRef={controlsRef}
            sceneData={sceneData}
            setTransformControlsMode={setTransformControlsMode}
            transformControlsMode={transformControlsMode}
            activeItem={active}
            itemEdited={itemEdited}
            setItemEdited={setItemEdited}
            rotate15degMode={rotate15degMode}
            setRotate15degMode={setRotate15degMode}
            setAddItemModalOpen={setAddItemModalOpen}
            stepModal={stepModal}
            setStepModal={setStepModal}
            step={step}
            setShowGrid={setShowGrid}
            showGrid={showGrid}
            setShowLoader={setShowLoader}
            setBackgroundImage={setBackgroundImage}
            setScaleBackground={setScaleBackground}
            scaleBackground={scaleBackground}
            setMapDataLoaded={setMapDataLoaded}
            setMapLoading={setMapLoading}
            setHelpModalActive={setHelpModalActive}
            setScaleModal={setScaleModal}
          />

          {scaleModal && (
            <div
              style={{
                position: "absolute",
                bottom: "2px",
                right: "2px",
                zIndex: 500,
              }}
            >
              <button
                onClick={() => {
                  saveScale();
                }}
                style={{
                  backgroundColor: "#FF00FA",
                  color: "white",
                  borderRadius: "5px",
                  border: 0,
                  outline: 0,
                  padding: "5px 10px",
                }}
              >
                Zatwiedź skalę
              </button>
            </div>
          )}

          <LeftMenuOpener setLeftMenuOpened={setLeftMenuOpened} />
          <LeftMenuComponent
            leftMenuOpened={leftMenuOpened}
            setLeftMenuOpened={setLeftMenuOpened}
            setAddItemModalOpen={setAddItemModalOpen}
            sceneData={sceneData}
            setFilterModal={setFilterModal}
            setDataType={setDataType}
          />

          <ToastContainer position="top-right" autoClose={2500} />

          {showAddressModal ? (
            <SetAddressModal
              selected={selected}
              setShowAddressModal={setShowAddressModal}
              defaultValue={defaultAddressModal}
              shopId={shopId}
              step={step}
            />
          ) : (
            ""
          )}

          {rotateElModal ? (
            <RotateElements
              selected={selected}
              setRotateElModal={setRotateElModal}
              shopId={shopId}
              sceneData={sceneData}
            />
          ) : (
            ""
          )}

          {splitElementModal ? (
            <SplitModal
              selected={selected}
              setSplitElementModal={setSplitElementModal}
              setContextMenuAction={setContextMenuAction}
              AddItemToCanvas={AddItemToCanvas}
              setObjectOnCanvas={setObjectOnCanvas}
              allObjects={allObjects}
              editMode={editMode}
              controlsRef={controlsRef}
              setCatalogueRows={setCatalogueRows}
            />
          ) : (
            ""
          )}

          {changeAddressModal ? (
            <ChangeAddressModal
              setChangeAddressModal={setChangeAddressModal}
              shopId={shopId}
              setSelected={setSelected}
              selected={selected}
            />
          ) : (
            ""
          )}

          {helpModalActive ? <HelpModal /> : ""}
        </>
      )}
      <Suspense fallback={<span>loading...</span>}>
        <Canvas
          ref={canvasRef}
          orthographic={true}
          camera={{ position: [0, 100, 0], fov: 35, zoom: 50 }}
          onWheel={() => wheelHandler()}
          frameloop="demand"
        >
          <MapControls
            zoomSpeed={3}
            makeDefault
            ref={controlsRef}
            enableDamping={false}
            enableRotate={!view2D}
            minZoom={10}
            zoom={50}
            maxPolarAngle={Math.PI / 2}
          />
          {active && selected.length === 1 && editMode === "2" && (
            <TransformControls
              space="local"
              showX={showX}
              showY={showY}
              showZ={showZ}
              object={active}
              mode={transformControlsMode}
              rotationSnap={(snapRotateNumber * Math.PI) / 180}
              translationSnap={0.01}
            />
          )}

          <Select
            multiple
            box
            onChange={(event) => setSelectedHandle(event)}
            onContextMenu={(e) => {
              setContextMenu({
                show: true,
                posX: e.pageX,
                posY: e.pageY,
              });
            }}
          >
            {/* <Instances /> */}
            <TexturedPlane />
            {[...objectOnCanvas]}
          </Select>

          {showGrid ? (
            <Grid
              position={[0, 0, 0]}
              gridSize={[100, 100]}
              cellSize={1}
              sectionSize={1}
              sectionColor="#eee"
              sectionThickness={1}
              infiniteGrid={true}
            />
          ) : (
            ""
          )}

          <ambientLight args={[0xffffff]} intensity={2} />
          <pointLight
            args={[0xffffff]}
            intensity={2}
            position={[-10, 10, -10]}
          />
          <Test />
          {/* <GizmoViewport /> */}
          {/* <Stats className="own-stats" /> */}
          {/* <TravelingSalesman
            sceneData={sceneData}
            itemUUID={[5404, 5482, 5391, 5384]}
            startPos={[-15, -30]}
          /> */}

          {/* {blocks &&
            blocks.length > 0 &&
            blocks.map((item, key) => (
              <mesh
                // visible={myZoom < 55 ? true : false}
                position={[item.x / 100 + item.w / 100 / 2, 0.01, item.y / 100]}
                key={key}
              >
                <Plane
                  args={[item.w / 100, item.h / 100 / 2]}
                  rotation={[-Math.PI / 2, 0, 0]}
                  onClick={(e) => console.log(e.object)}
                  userData={{ address: item.address }}
                  material-color={"#" + item.color}
                >
                  <meshPhongMaterial color={"#" + item.color} />
                </Plane>

                <Text
                  textAlign="center"
                  anchorX="center"
                  anchorY="middle"
                  position={[0, 0.01, 0]}
                  maxWidth={item.w / 100 - 0.1}
                  rotation={[-Math.PI / 2, 0, 0]}
                  fontSize={0.5}
                  color="black"
                >
                  {item.address}
                </Text>
              </mesh>
            ))} */}
        </Canvas>
      </Suspense>
      {editMode === "2" && (
        <>
          <RightMenuOpener setRightMenuOpened={setRightMenuOpened} />
          <RightMenu
            rightMenuOpened={rightMenuOpened}
            setRightMenuOpened={setRightMenuOpened}
            backgroundImage={backgroundImage}
            setBackgroundImage={setBackgroundImage}
            shopId={shopId}
            scaleBackground={scaleBackground}
            setMapLoading={setMapLoading}
          />
          {addItemModalOpen && (
            <AddNewItemModal
              setObjectOnCanvas={setObjectOnCanvas}
              setAddItemModalOpen={setAddItemModalOpen}
              controlsRef={controlsRef}
              allObjects={allObjects}
              catalogueRows={catalogueRows}
              setCatalogueRows={setCatalogueRows}
              setAddItemModalOpen={setAddItemModalOpen}
              transformControlsMode={transformControlsMode}
            />
          )}
          {active && (
            <BottomSummaryBar
              activeItem={active}
              selected={selected}
              setSelected={setSelected}
              sceneData={sceneData}
            />
          )}
          {filterModal && (
            <FilterModal
              filterModal={filterModal}
              setFilterModal={setFilterModal}
            />
          )}

          {scaleBackgroundModal && scaleBackgroundMode && (
            <ScaleBackgroundModal
              setScaleBackgroundModal={setScaleBackgroundModal}
              scaleBackground={scaleBackground}
              setScaleBackground={setScaleBackground}
            />
          )}
        </>
      )}

      {editMode !== "0" && (
        <RightAdvancedMenu
          advancedMenuActive={advancedMenuActive}
          selected={selected}
          setContextMenuAction={setContextMenuAction}
          setObjectOnCanvas={setObjectOnCanvas}
          allObjects={allObjects}
        />
      )}
    </div>
  );
}

export default App;
