import axios from "axios";
import { API_URL } from "../../env";
import { AddItemToCanvas } from "./AddItemToCanvas";
import { SelectItems } from "./SelectItems";

export const CopingGroupElements = (elements, props) => {
  const {
    setObjectOnCanvas,
    allObjects,
    sceneData,
    setSelected,
    setMapDataLoaded,
    setPastedElements,
  } = props;

  let minX = _.minBy(elements, function (o) {
    return o.position.x;
  });

  let maxX = _.maxBy(elements, function (o) {
    return o.position.x;
  });

  let minY = _.minBy(elements, function (o) {
    return o.position.z;
  });

  let maxY = _.maxBy(elements, function (o) {
    return o.position.z;
  });

  const distanceX = maxX.position.x - minX.position.x;
  const distanceY = maxY.position.z - minY.position.z;

  let itemsArray = [];
  if (elements.length > 0) {
    elements.forEach((item) => {
      const copyOfElement = item.clone();
      if (distanceX > distanceY) {
        copyOfElement.position.set(
          copyOfElement.position.x,
          copyOfElement.position.y,
          copyOfElement.position.z + distanceY + 2
        );
      } else {
        copyOfElement.position.set(
          copyOfElement.position.x + distanceX + 2,
          copyOfElement.position.y,
          copyOfElement.position.z
        );
      }
      itemsArray.push({
        uniqueId: item.uuid,
        shapeId: item.shapeId,
        address: item.address,
        objectName: item.shape,
        color: item.color,
        addIncrement: 0,
        x: Math.floor(copyOfElement.position.x * 100),
        y: Math.floor(copyOfElement.position.z * 100),
        w: item.geometry.parameters.width * 100,
        h: item.geometry.parameters.height * 100,
        rX: copyOfElement.rotation._x,
        rY: copyOfElement.rotation._y,
        rZ: copyOfElement.rotation._z,
      });
    });
  }

  const body = {
    shopId: props.shopId,
    items: itemsArray,
  };

  const headers = {
    "Content-Type": "application/json",
  };

  // setMapDataLoaded(false);
  setSelected([]);
  axios
    .post(API_URL + "/VirtualStore/newElement", JSON.stringify(body), {
      headers,
    })
    .then((response) => {
      response.data.items.forEach((item) => {
        AddItemToCanvas({
          setObjectOnCanvas,
          key: item.uniqueId,
          rX: item.rX,
          rY: item.rY,
          rZ: item.rZ,
          uniqueId: item.uniqueId,
          shapeId: item.shapeId,
          address: item.address,
          department: item.department,
          shape: item.objectName,
          color: item.color,
          position: [item.x / 100, item.h / 100 / 2, item.y / 100],
          shelfs: item.shelfs,
          allObjects: allObjects,
          attributes: item.attributes,
        });
      });
      setTimeout(() => {
        SelectItems(sceneData, response.data.items, setSelected);
        setPastedElements(response.data.items);
      }, 100);
    })
    .then(() => setMapDataLoaded(true));
};
