import React from "react";
import { Object } from "../Objects/Object";
import { SetCatalogueItems } from "./SetCatalogueItems";

export const AddItemToCanvas = (props) => {
  const catalogue = SetCatalogueItems(props.catalogue);
  props.setObjectOnCanvas((prevState) => [
    ...prevState,
    <Object
      key={props.key}
      rX={props.rX}
      rY={props.rY}
      rZ={props.rZ}
      uniqueId={props.uniqueId}
      shapeId={props.shapeId}
      address={props.address}
      department={props.department}
      shape={props.shape}
      color={props.color}
      position={props.position}
      shelfs={props.shelfs}
      allObjects={props.allObjects}
      editMode={props.editMode}
      rotation={props.rotation}
      x1={props.x1}
      x2={props.x2}
      y1={props.y1}
      y2={props.y2}
      mainObject={props.mainObject}
      attributes={props.attributes}
    />,
  ]);
};
