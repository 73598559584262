import "./TopBar.scss";
import { BiChevronDown } from "react-icons/bi";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const TopBar = (props) => {
  const [toogledMenu, setToogledMenu] = useState(false);
  const { zones } = props;
  const navigate = useNavigate();
  const { shopId, uids, selectedId, editMode, zoneId } = useParams();
  const currentZoneName = _.find(zones, function (o) {
    return o.zoneId == zoneId;
  });

  const toogleZonesMenu = () => {
    setToogledMenu(!toogledMenu);
  };

  const setZone = (zoneId) => {
    navigate(`/${editMode}/${shopId}/${zoneId}/${uids}/${selectedId}`);
    navigate(0);
    setToogledMenu(false);
  };

  return (
    <>
      <div className="top-bar-contaienr">
        {editMode !== "2" && (
          <div
            className="change-zone-container"
            onClick={() => {
              toogleZonesMenu();
            }}
          >
            {currentZoneName ? currentZoneName.zoneName : "Wybierz strefę"}{" "}
            <BiChevronDown />
          </div>
        )}

        {editMode !== "2" && toogledMenu && (
          <div className="zones-menu">
            {zones.map((item, key) => {
              return (
                <div
                  key={key}
                  className="item"
                  onClick={() => setZone(item.zoneId)}
                >
                  {item.zoneId}. {item.zoneName}
                </div>
              );
            })}
          </div>
        )}
        <div>{props.shopname}</div>
      </div>
    </>
  );
};
