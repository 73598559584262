export const GetAllItems = (sceneData) => {
  if (sceneData) {
    let items = [];
    sceneData.children.forEach(child=>{
      if (child.type === "Group" && child.children.length > 0) {
          child.children.forEach((item)=>{
              items.push(item)
          })
      }
    })
    return items;
  }
}