import { useEffect, useState } from "react";
import "./SplitModal.scss";
import { IoMdClose } from "react-icons/io";
import { IoIosAddCircle } from "react-icons/io";
import axios from "axios";
import { API_URL } from "../../../env";

export const SplitModal = (props) => {
  const [splitRows, setSplitRows] = useState([]);
  const {
    selected,
    setSplitElementModal,
    setContextMenuAction,
    AddItemToCanvas,
    setObjectOnCanvas,
    allObjects,
    editMode,
    controlsRef,
  } = props;

  const addEmptyRow = () => {
    const newSplitRows = [...splitRows];
    newSplitRows.unshift({ index: splitRows.length + 1, value: 0 });
    setSplitRows(newSplitRows);
  };

  const updateRow = (index, value) => {
    const newSplitRows = [...splitRows];
    newSplitRows[index].value = Number(value);
    setSplitRows(newSplitRows);
  };

  const deleteSelected = () => {
    setContextMenuAction("deleteItem");
  };
  const addNewItemToMap = (items) => {
    items.forEach((item) => {
      AddItemToCanvas({
        setObjectOnCanvas,
        key: item.uniqueId,
        rX: item.rX,
        rY: item.rY,
        rZ: item.rZ,
        uniqueId: item.uniqueId,
        shapeId: item.shapeId,
        address: item.address,
        department: item.department,
        shape: item.objectName,
        color: item.color,
        position: [item.x / 100, item.z / 100 + item.h / 100 / 2, item.y / 100],
        shelfs: item.shelfs,
        allObjects: allObjects,
        editMode: editMode,
        rotation: item.rotation,
        controlsRef: controlsRef.current,
        x1: item.x1_frame,
        x2: item.x2_frame,
        y1: item.y1_frame,
        y2: item.y2_frame,
        attributes: item.attributes,
      });
    });
  };

  const getCatalogueRows = () => {
    setContextMenuAction("reloadCatalogue");
  };

  const sendToApi = () => {
    const data = { items: [] };

    selected.forEach((item) => {
      data.items.push({ id: item.uuid, rows: splitRows });
    });

    axios.post(API_URL + "/VirtualStore/postElementSplit", data).then((res) => {
      if (res.data.status.success) {
        deleteSelected();

        setTimeout(() => {
          addNewItemToMap(res.data.items);
        }, 1000);

        setSplitElementModal(false);
      }
    });
  };

  return (
    <div className="split-modal">
      <div
        className="add-icon"
        onClick={() => {
          addEmptyRow();
        }}
      >
        <div style={{ height: "100%", display: "flex", flex: 1 }}>
          <IoIosAddCircle size={25} />
        </div>
      </div>
      <div
        className="split-modal-close"
        onClick={() => {
          setSplitElementModal(false);
        }}
      >
        <IoMdClose />
      </div>
      <div className="split-modal-content">
        <div>
          {splitRows && splitRows.length > 0
            ? splitRows.map((row, key) => {
                return (
                  <div className="item-row" key={key}>
                    <div style={{ marginRight: "10px" }}>{row.index}</div>
                    <div>
                      <input
                        key={Math.floor(Math.random() * 1001) + "-min"}
                        type="number"
                        defaultValue={row.value}
                        onBlur={(e) => {
                          updateRow(key, e.target.value);
                        }}
                      />
                      cm
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
        <div style={{ marginTop: "10px", textAlign: "center" }}>
          Liczba zaznaczonych elementów: <strong>{selected.length}</strong>
        </div>
      </div>
      <div>
        <button
          onClick={() => {
            sendToApi();
          }}
        >
          Zapisz
        </button>
      </div>
    </div>
  );
};
