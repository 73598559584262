import { useState } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

export const MoveElementsComponent = (props) => {
  const { setContextMenuAction, contextMenuValue } = props;
  const [moveValue, setMoveValue] = useState(50);

  const MoveElementsIconsStyle = {
    fontSize: "14px",
    margin: 0,
    padding: 0,
  };

  const MoveElementsButtonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 2px",
    border: "0",
    borderRadius: "3px",
  };

  const MoveElementsValue = {
    borderRadius: "3px",
    margin: "0 2px",
    border: 0,
  };

  const rightClickHandle = () => {
    setMoveValue(moveValue + 50);
  };

  const leftClickHandle = () => {
    if (moveValue >= 50) {
      setMoveValue(moveValue - 50);
    }
  };

  const moveElements = (value) => {
    setContextMenuAction({ type: contextMenuValue, value: value });
  };

  return (
    <div style={{ display: "flex", flexWrap: "nowrap" }}>
      <button style={MoveElementsButtonStyle} onClick={() => leftClickHandle()}>
        <BsArrowLeftShort style={MoveElementsIconsStyle} />
      </button>
      <button style={MoveElementsValue} onClick={() => moveElements(moveValue)}>
        {moveValue}cm
      </button>
      <button
        style={MoveElementsButtonStyle}
        onClick={() => rightClickHandle()}
      >
        <BsArrowRightShort style={MoveElementsIconsStyle} />
      </button>
    </div>
  );
};
