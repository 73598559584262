import "./ScaleBackgroundModal.scss";
import { AiOutlineClose } from "react-icons/ai";

export const ScaleBackgroundModal = (props) => {
  const { setScaleBackgroundModal, setScaleBackground } = props;

  const closeHandler = () => {
    setScaleBackgroundModal(false);
  };
  return (
    <div className="scale-modal-container">
      <div className="close-button" onClick={() => closeHandler()}>
        <AiOutlineClose />
      </div>
      <label>Wprawdź skalę mapy:</label>
      <input
        type="number"
        defaultValue={props.scaleBackground}
        onChange={(e) => setScaleBackground(e.target.value)}
        step={0.1}
      />
    </div>
  );
};
