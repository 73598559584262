import { Oval } from "react-loader-spinner";
import "./Loader.scss";

export const LoaderComponent = (props) => {
  return (
    <div className="loader-container">
      <Oval color="black" secondaryColor="gray" width={50} height={50} />
      <h5>Trwa ładowanie danych ... proszę czekać</h5>
    </div>
  );
};
