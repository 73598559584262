import React from "react";
import { useEffect } from "react";

export const ActionsHistory = (props) => {
  const { action } = props;
  let history = [];
  history.push(action);

  history.length = Math.min(history.length, 10);

  const event = new CustomEvent("history", {
    detail: {
      history: history,
    },
  });
  window.dispatchEvent(event);
};
