import "./ContextMenuItem.scss";

export const ContextMenuItem = (props) => {
  return (
    <div
      className={`context-menu-item ${props.disabled ? "item-disabled" : ""}`}
    >
      {props.icon}
      <div className="item-name">{props.name}</div>
      <div>{props.additional}</div>
    </div>
  );
};
