import axios from "axios";
import React, { useRef } from "react";
import { useState } from "react";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { API_URL } from "../../env";
import "./LeftMenuItem.scss";

export const LeftMenuItem = (props) => {
  const [itemToogled, setItemToogled] = useState(true);

  const { children, setLeftMenuOpened, setFilterModal, setDataType } = props;

  const itemRef = useRef();

  let arrayOfChildren = [];
  if (children) {
    children.forEach((child, key) => {
      arrayOfChildren.push(
        <LeftMenuItem
          key={key}
          name={child.name}
          color={child.color}
          level={props.level + 1}
          children={child.suboptions}
          id={child.id}
          setLeftMenuOpened={setLeftMenuOpened}
          setFilterModal={setFilterModal}
          setDataType={setDataType}
        />
      );
    });
  }

  const clickHandle = (event) => {
    if (arrayOfChildren.length > 0) {
      setItemToogled(!itemToogled);
    } else {
      setLeftMenuOpened(false);
      axios
        .post(API_URL + "/VirtualStore/getFilters/" + props.id)
        .then((response) => {
          if (response.data.length > 0) {
            setFilterModal(response.data);
          } else {
            setDataType(props.id);
          }
        });
    }
    event.stopPropagation();
  };

  const itemStyle = {
    marginLeft: props.level > 0 ? "1px" : "0",
    borderLeft: "5px solid " + props.color,
    minHeight: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5px",
    marginBottom: "5px",
    paddingLeft: "6px",

    cursor: "pointer",
    zIndex: props.level,
  };

  return (
    <div
      ref={itemRef}
      className="left-menu-item"
      style={{ width: "100%" }}
      onMouseOver={() => {
        if (!itemToogled) {
          itemRef.current.style.backgroundColor = "#333333";
        } else {
          itemRef.current.style.backgroundColor = "#444444";
        }
      }}
      onMouseLeave={() => {
        itemRef.current.style.backgroundColor = "#333333";
      }}
    >
      <div style={itemStyle} onClick={(event) => clickHandle(event)}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ width: "100%" }}>
            <h5>{props.name}</h5>
            <div className={`children ${itemToogled ? "hidden" : "visible"}`}>
              {arrayOfChildren}
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "12px" }}>
            {arrayOfChildren.length > 0 ? (
              itemToogled ? (
                <BsArrowDownShort />
              ) : (
                <BsArrowUpShort />
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
