import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { TravelingSalesman2 } from "./components/TravelingSalesman/TravelingSalesman2";

const router = createHashRouter([
  {
    path: "/:editMode/:shopId/:zoneId/:uids/:selectedId",
    element: <App />,
  },
  {
    path: "/api/salesman/:pathId",
    element: <TravelingSalesman2 />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

reportWebVitals();
