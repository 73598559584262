import { FiEdit } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

export const EditButton = (props) => {
  const { shopId } = props;
  const { zoneId, uids, selectedId } = useParams();

  const navigate = useNavigate();

  const editButtonStyle = {
    position: "absolute",
    top: 10,
    right: 10,
    fontSize: "25px",
    color: "red",
    cursor: "pointer",
    zIndex: "5000",
  };

  const changeMode = () => {
    navigate(`/2/${shopId}/${zoneId}/${uids}/${selectedId}`);
    navigate(0);
  };

  return (
    <div
      onClick={() => {
        changeMode();
      }}
    >
      <FiEdit style={editButtonStyle} />
    </div>
  );
};
