import React from "react";
import "./LeftMenuComponent.scss";
import { RiCloseCircleLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../env";
import { LeftMenuItem } from "./LeftMenuItem";

export const LeftMenuComponent = (props) => {
  const { leftMenuOpened, setLeftMenuOpened, setFilterModal, setDataType } =
    props;
  const [menuStructure, setMenuStructure] = useState();
  const [getData, setGetData] = useState(false);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
    };
    if (!getData) {
      axios
        .post(API_URL + "/VirtualStore/getMenuItems", null, { headers })
        .then((response) => {
          setMenuStructure(response.data);
          setGetData(true);
        });
    }
  }, [getData]);

  return (
    <div
      className={`left-menu-container ${
        leftMenuOpened ? "left-showMenu" : "left-hideMenu"
      }`}
    >
      <div className="modal-content">
        <div className="top-menu-content">
          <RiCloseCircleLine
            onClick={() => {
              setLeftMenuOpened(false);
            }}
          />
        </div>
        <div className="left-menu-main-content">
          {menuStructure &&
            menuStructure.map((item, key) => {
              return (
                <LeftMenuItem
                  key={key}
                  name={item.name}
                  color={item.color}
                  children={item.suboptions}
                  level={0}
                  setLeftMenuOpened={setLeftMenuOpened}
                  setFilterModal={setFilterModal}
                  setDataType={setDataType}
                />
              );
            })}
        </div>
        <div>{/*bottom*/}</div>
      </div>
    </div>
  );
};
